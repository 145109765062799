import React from "react";
import { styled } from "styled-components";

export default function AnimatedCard2({
  heading,
  text,
  bgColor,
  bgPic,
  onClick,
}) {
  return (
    <Wrapper bgPic={bgPic} bgColor={bgColor} onClick={onClick}>
      <div className="heading-text-icon">
        <div className="heading">
          <h1>
            Explore {heading[0]}
            <br />
            {heading[1]}
          </h1>
        </div>
        <div className="text">
          <p>
            {text[0]}
            <br /> {text[1]}
          </p>
        </div>
        <div className="icon">
          <span className="inner-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </span>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;

  &:hover {
    width: 100%;
  }

  border-radius: 15px;
  * {
    border-radius: 15px;
  }
  background-image: ${({ bgPic }) => `url(${bgPic})`};
  background-size: cover;

  background-position: center;

  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  .heading-text-icon {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 25px;
    gap: 20px;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: ${({ bgColor }) => bgColor};
      transition: height 0.3s ease;
    }
    &:hover::before {
      height: 100%;
    }
    &:hover {
      .icon {
        display: flex;

        justify-content: start;
        align-items: center;
        width: 100%;

        z-index: 2;
        .inner-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: #f5f5f7;
        }
      }
      .text {
        display: flex;
        z-index: 2;
        justify-content: start;
        align-items: center;
        width: 100%;

        p {
          color: white;
          font-weight: 400;
          font-size: 25px;
        }
      }
    }
    .text {
      display: none;
    }
    .icon {
      display: none;
    }

    .heading {
      display: flex;
      z-index: 2;
      justify-content: start;
      align-items: center;
      width: 100%;

      h1 {
        color: white;
        font-weight: 400;
        font-size: 45px;
      }
    }
  }
`;
