import React, { useState } from "react";
import { styled } from "styled-components";
import PlansComponent from "../../components/Plans/planCards";
import SignupForm from "./signupForm";

let initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phNumber: "",
  password: "",
  confirmPassword: "",
  gender: "",
  birthDay: "",
  shippingAddress: "",
  zipCode: "",
  city: "",
  state: "",
  country: "",
  receiverNumber: "",
};

export default function SignUp(params) {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    "Step 1: Choose an option",
    "Step 2: Provide details",
    // "Step 3: Confirm your selection",
  ];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };
  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  ////
  const [formData, setFormData] = useState(initialValues);
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData(initialValues);
    ///handle Submit
  };

  ////
  return (
    <Wrapper>
      <FormWrapperComponent
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
        nextStep={nextStep}
        previousStep={previousStep}
        handleSubmit={handleSubmit}
      >
        {currentStep === 0 && (
          <ChoosePlan currentStep={currentStep} nextStep={nextStep} />
        )}
        {currentStep === 1 && (
          <SignupFormWrapperFunction
            setFormData={setFormData}
            formData={formData}
          />
        )}
      </FormWrapperComponent>
    </Wrapper>
  );
}

function SignupFormWrapperFunction({ formData, setFormData }) {
  return (
    <SignupFormWrapper>
      <SignupForm setFormData={setFormData} formData={formData} />
    </SignupFormWrapper>
  );
}

const SignupFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  padding: 100px 10px 50px 10px;
  @media (max-width: 800px) {
    padding: 100px 0px 0px 0px;
  }
`;

function ChoosePlan({ currentStep, nextStep }) {
  return (
    <PlanWrapper>
      <PlansComponent nextStep={nextStep} forSignup={true} />
    </PlanWrapper>
  );
}

const PlanWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  padding: 100px 15px 15px 15px;
  @media (max-width: 800px) {
    padding: 100px 0px 0px 0px;
  }
`;

function FormWrapperComponent({
  children,
  steps,
  nextStep,
  previousStep,
  currentStep,
  setCurrentStep,
  handleSubmit,
}) {
  return (
    <FormWrapper currentStep={currentStep}>
      <button
        disabled={currentStep === 0 ? true : false}
        onClick={previousStep}
        style={{
          background: `${currentStep === 0 ? "#f5f5f7" : "#1d1d1f"}`,
        }}
        className="back-btn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="white"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
          />
        </svg>
      </button>
      <div className="step-count">
        {steps.map((d, i) => (
          <div
            className="step"
            style={{
              background: `${currentStep === i ? "#1d1d1f" : "#f5f5f7"}`,
              color: `${currentStep === i ? "#f5f5f7" : "#1d1d1f"}`,
            }}
          >
            {i + 1}
          </div>
        ))}
      </div>
      {children}
      {currentStep < 1 ? (
        <button
          disabled={currentStep === 2 ? true : false}
          onClick={nextStep}
          className="next-btn"
          style={{
            background: `${
              currentStep === 1 || currentStep === 0 ? "#f5f5f7" : "#1d1d1f"
            }`,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="white"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </button>
      ) : (
        <button onClick={handleSubmit} className="submit-btn">
          Submit
        </button>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 10px;
  @media (max-width: 800px) {
    padding: 5px;
  }
  border-radius: 15px;

  .step-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 55px;
    padding: 5px;
    position: absolute;
    top: 15px;
    gap: 5px;
    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: #f5f5f7;
      // cursor: pointer;
    }
  }
  .back-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #1d1d1f;
    position: absolute;

    left: 15px;
    @media (max-width: 800px) {
      top: 10px;
      margin-top: 8px;
    }
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #fa5c40;
    }
  }
  .next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #1d1d1f;
    position: absolute;
    right: 15px;
    @media (max-width: 800px) {
      top: 10px;
      margin-top: 8px;
    }
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #fa5c40;
    }
  }
  .submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 50px;
    border-radius: 10px;
    background: #1d1d1f;
    position: absolute;
    right: 15px;
    @media (max-width: 800px) {
      top: 10px;
      margin-top: 8px;
    }
    color: white;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #fa5c40;
    }
  }
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-color: #f5f5f7;
  padding: 50px;
  margin-top: 100px;

  @media (max-width: 800px) {
    padding: 10px;
    min-height: 100vh;
    height: auto;
  }
`;
