import { motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";
import Partners from "../../components/Partners/partners";
import AboutPageCard from "../../components/Card/aboutPageCard";

import bgPlans from "../../assets/business-plan.jpg";
import SEO from "../../components/HeadSEOComponent/HeadSEO";
export default function BusinessSolutions({ screen }) {
  return (
    <>
      <SEO
        title="Solutions | DelCargo"
        description="Streamlined Shipping Solutions for Global Growth."
      />
      <Wrapper>
        <div className="heading-div">
          <h1>Elevate Your Business to New Heights</h1>
          <h2>DELCARGO</h2>
        </div>
        <div className="heading-div">
          {/* <h1>Our Mission</h1> */}
          <p>
            Delcargo: Streamlined Shipping Solutions for Global Growth. Let us
            handle the logistics while you thrive internationally. Seamless
            support, competitive rates, and white-glove service for your
            business success.
          </p>
        </div>
        <div className="solution-cards">
          <SolutionCard
            heading="WAREHOUSE"
            text="DelCargo warehouse can hold orders for 60 days before shipping them."
            bgColor={"#29525e"}
            bgPic={
              "https://images.pexels.com/photos/4483610/pexels-photo-4483610.jpeg?auto=compress&cs=tinysrgb&w=600"
            }
          />
          <SolutionCard
            heading="DROPSHIPPING"
            text="DelCargo ships and delivers international orders directly to customers."
            bgColor={"#29525e"}
            bgPic={
              "https://images.pexels.com/photos/7310245/pexels-photo-7310245.jpeg?auto=compress&cs=tinysrgb&w=600"
            }
          />
          <SolutionCard
            heading="B2B"
            text="B2B trade: brand/company/seller deals with GS, not customers directly."
            bgColor={"#29525e"}
            bgPic={
              "https://images.pexels.com/photos/416405/pexels-photo-416405.jpeg?auto=compress&cs=tinysrgb&w=600"
            }
          />
          <SolutionCard
            heading="WEB SERVICES"
            text="Smooth access to web services, contact us anytime for assistance."
            bgColor={"#29525e"}
            bgPic={
              "https://images.pexels.com/photos/205316/pexels-photo-205316.png?auto=compress&cs=tinysrgb&w=600"
            }
          />
        </div>
        <AboutPageCard
          desc1={[
            "Unleash Your Buying Potential:",
            "Embrace Benefits, Evade Taxes!",
          ]}
          desc2="DelCargo: Your go-to shopping and shipping service! No more waiting for family visits. Tax-free shipping, US address, consolidation, and fast delivery. Save time and money!"
          btnHeading="Plans"
          btnLink="plans"
          cardimage={bgPlans}
        />

        <Partners screen={screen} />
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
  }

  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    list-style: square;
    padding-left: 100px;
    @media (max-width: 800px) {
      padding-left: 15px;
    }
    li {
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
    }
  }
  .solution-cards {
    display: flex;
    flex-direction: row;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .heading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    padding: 50px;
    @media (max-width: 800px) {
      padding: 10px;
      height: 350px;
    }

    p {
      text-align: center;
      font-size: 30px;
      padding: 50px 100px 100px 100px;
      @media (max-width: 800px) {
        padding: 10px;
      }
    }
    h2 {
      font-size: 25px;
      font-weight: 400;
      color: #1d1d1f;
    }
    h1 {
      font-size: 50px;
      font-weight: 600;
      color: #1d1d1f;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 50px;
        font-weight: 500;
      }
    }
  }
`;

function SolutionCard({ heading, text, bgColor, bgPic }) {
  return (
    <>
      <WrapperCard bgPic={bgPic} bgColor={bgColor}>
        <div className="div-image"></div>
        <div className="heading-text-icon">
          <div className="heading">
            <h1>{heading}</h1>
          </div>
          <div className="text">
            <p>{text}</p>
          </div>
        </div>
      </WrapperCard>
    </>
  );
}

const WrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 15px;
  * {
    border-radius: 15px;
  }

  cursor: pointer;
  transition: all 0.3s ease-in-out;
  .div-image {
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%;
    height: 220px;
    background-image: ${({ bgPic }) => `url(${bgPic})`};
    background-size: cover;
    // background-attachment: fixed;
    background-position: center;

    background-repeat: no-repeat;
  }
  .heading-text-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 235px;
    padding: 25px;
    gap: 20px;
    position: relative;
    overflow: hidden;
    background-color: ${({ bgColor }) => bgColor};

    .text {
      display: flex;
      z-index: 2;
      justify-content: start;
      align-items: center;
      width: 100%;
      //   height: 100%;

      p {
        color: white;
        font-weight: 400;
        font-size: 20px;
      }
    }

    .heading {
      display: flex;
      z-index: 2;
      justify-content: start;
      align-items: center;
      width: 100%;
      //   height: 100%;

      h1 {
        color: white;
        font-weight: 400;
        font-size: 30px;
      }
    }
  }
`;
