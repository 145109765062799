import React from "react";
import styled from "styled-components";

export default function AboutButton() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button></Button>
    </div>
  );
}

const Button = styled.div`
  width: 150px;
  height: 50px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  border: 1px solid #1d1d1f;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "About";
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    font-weight: 400;
    border-radius: 10px;
    color: #1d1d1f;

    transition: all 0.4s ease-in-out;
  }
  &:before {
    content: "About";
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 0;
    font-weight: 400;
    background-color: #1d1d1f;
    color: #f5f5f7;
    border-radius: 6px;
    transition: all 0.4s ease-in-out;
  }
  &:hover:before {
    transform: translateY(-50px);
  }
  &:hover:after {
    transform: translateY(-50px);
  }
`;
