import { motion } from "framer-motion";
import React from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import { articles } from "./writtenArticles";
import SEO from "../../components/HeadSEOComponent/HeadSEO";
export default function Article({ screen }) {
  const { id } = useParams();
  const filteredArticles = articles.filter((article) => article.path === id);
  return (
    <>
      <SEO
        title={filteredArticles[0].title}
        description={filteredArticles[0].description}
      />
      <Wrapper>
        <div className="heading-div">
          <h1>{filteredArticles[0].title}</h1>
          <p>{filteredArticles[0].description}</p>
          <br />
          <span>
            By{" "}
            <a
              href="https://www.linkedin.com/in/hahmadqayyum/"
              rel="noreferrer"
              target="_blank"
            >
              Muqeet Ahmad
            </a>
          </span>
          <br />

          <time>{filteredArticles[0].date}</time>
        </div>
        <div
          className="content-div"
          dangerouslySetInnerHTML={{ __html: filteredArticles[0].content }}
        ></div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
  }

  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    list-style: square;
    padding-left: 100px;
    @media (max-width: 800px) {
      padding-left: 15px;
    }
    li {
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
    }
  }

  .content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 70vw;

    @media (max-width: 800px) {
      width: 100vw;
      padding: 20px;
      * {
        text-align: left;
      }
    }
    height: auto;

    h2 {
      font-size: 40px;
      font-weight: 500;
      padding-bottom: 20px;
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (max-width: 800px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .heading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    padding: 0px 50px 50px 50px;
    @media (max-width: 800px) {
      padding: 10px;
    }
    time {
      font-family: "Suisse", Menlo, Consolas, Monaco, Liberation Mono,
        Lucida Console, monospace;
    }
    span {
      a {
        color: #fa5c40;
        text-decoration: underline;
      }
    }
    p {
      font-style: italic;
      font-size: 20px;
      font-weight: 400;
      color: #1d1d1f;
      text-align: center;
    }
    h1 {
      font-size: 30px;
      font-weight: 600;
      color: #1d1d1f;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 35px;
        font-weight: 500;
      }
    }
  }
`;
