import React from "react";
import styled from "styled-components";

const Accordion = ({
  title,
  content,
  activeIndex,
  index,
  onAccordionClick,
}) => {
  const isActive = index === activeIndex;

  const toggleAccordion = () => {
    onAccordionClick(index);
  };

  return (
    <AccordionContainer>
      <AccordionButton active={isActive} onClick={toggleAccordion}>
        <p>{title}</p>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
      </AccordionButton>
      <AccordionPanel active={isActive}>
        <p>{content}</p>
      </AccordionPanel>
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div`
  background-color: white;
  color: #1d1d1f;
  width: 100%;
  border: none;
  border-radius: 15px;
  text-align: left;
  outline: none;
  font-size: 20px;
  transition: 0.4s;
  margin-bottom: 10px;
`;

const AccordionButton = styled.button`
  background-color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  border-radius: 15px;
  outline: none;
  font-size: 20px;
  font-weight: 500;
  transition: 0.4s;
  position: relative;
  display: flex;
  flex-direction: row;
  p {
    display: flex;
    width: 100%;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;

    svg {
      transform: ${({ active }) =>
        active ? "rotateZ(90deg)" : "rotateZ(0deg)"};
    }
  }
`;

const AccordionPanel = styled.div`
  padding: 25px;
  display: ${({ active }) => (active ? "block" : "none")};
  background-color: white;
  overflow: hidden;
`;

export default Accordion;
