import { AnimatePresence, motion } from "framer-motion";
import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { styled } from "styled-components";

export default function CustomVideoPlayer({ url, screen, setVideoURL }) {
  let playerRef = useRef();
  return (
    <>
      <AnimatePresence>
        {url !== "" && (
          <Wrapper>
            <motion.div
              key="video-modal"
              variants={{
                hidden: {
                  height: "0vh",
                  width: `0vw`,
                },
                visible: {
                  height: `${screen.width < 800 ? "100vh" : "70vh"}`,
                  width: `${screen.width < 800 ? "100vw" : "70vw"}`,
                },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{
                duration: 0.5,
              }}
              className="video-div"
            >
              <ReactPlayer
                ref={playerRef}
                url={url}
                playing
                controls
                width="auto"
                height="auto"
                style={{
                  aspectRatio: 16 / 9,
                  // minWidth: "100%",
                  // minHeight: "100%",
                  // position: "absolute",
                  // top: "50%",
                  // left: "50%",
                  // transform: "translate(-50%, -50%)",
                  // objectFit: "contain",
                }}
              />
            </motion.div>
            <div className="close-btn" onClick={() => setVideoURL("")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(119, 119, 119, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10%;
    right: 10%;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: white;
    cursor: pointer;
  }
  .video-div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 70vw;
    height: 70vh;
    @media (max-width: 800px) {
      width: 100vw;
      height: 100vh;
    }
  }
`;
