import React from "react";
import { styled } from "styled-components";
import AnimatedCard2 from "../../../components/Card/AnimatedCard2";
import bgSolutions from "../../../assets/business-solutions.png";

import bgPlans from "../../../assets/business-plan.jpg";
import AnimatedCard2Mobile from "../../../components/Card/AnimatedCard2Mobile";
import { useNavigate } from "react-router-dom";
export default function SectionCards({ screen }) {
  let navigate = useNavigate();
  return (
    <>
      {screen.width < 800 ? (
        <Wrapper>
          <AnimatedCard2Mobile
            heading={["", "Plans"]}
            text={[
              "Unleash Your Buying Potential:",
              "Embrace Benefits, Evade Taxes!",
            ]}
            bgColor={"#29525e"}
            bgPic={bgPlans}
            onClick={() => navigate("/plans")}
          />
          <AnimatedCard2Mobile
            heading={["Business", "Solutions"]}
            text={["Elevate Your Business to New Heights", "with DelCargo"]}
            bgColor={"#fa5c40"}
            bgPic={bgSolutions}
            onClick={() => navigate("/solutions")}
          />
        </Wrapper>
      ) : (
        <Wrapper>
          <AnimatedCard2
            heading={["", "Plans"]}
            text={[
              "Unleash Your Buying Potential:",
              "Embrace Benefits, Evade Taxes!",
            ]}
            bgColor={"#29525e"}
            bgPic={bgPlans}
            onClick={() => navigate("/plans")}
          />
          <AnimatedCard2
            heading={["Business", "Solution"]}
            text={["Elevate Your Business to New Heights", "with DelCargo"]}
            bgColor={"#fa5c40"}
            bgPic={bgSolutions}
            onClick={() => navigate("/solutions")}
          />
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
    min-height: 100vh;
    height: auto;
    padding: 10px;
  }
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  gap: 15px;
  padding: 50px;
`;
