import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation, useScroll } from "framer-motion";
export default function HoverButton({
  btnWidth,
  textSize,
  onClick,
  btnHeight,
  textWhite,
}) {
  const { scrollYProgress, scrollY } = useScroll();
  let [isAnimated, setIsAnimated] = useState(false);
  const animationControls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollY.current >= 100) {
        animationControls.start("visible");
        setIsAnimated(true);
      } else {
        animationControls.start("hidden");
        setIsAnimated(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollYProgress, animationControls, scrollY]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        onClick={onClick}
        style={{
          width: btnWidth,
          height: btnHeight,
          fontSize: textSize,
        }}
        isAnimated={isAnimated}
        textWhite={textWhite}
        variants={{
          hidden: {
            border: "2px solid #f5f5f7",
          },
          visible: {
            border: `2px solid ${textWhite ? "#f5f5f7" : "#1d1d1f"}`,
          },
        }}
        initial="hidden"
        animate={animationControls}
        transition={{ duration: 0.2 }}
      ></Button>
    </div>
  );
}

const Button = styled(motion.div)`
  background-color: transparent;
  border: 2px solid #f5f5f7;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "SignUp";
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    font-weight: bold;
    border-radius: 10px;
    color: ${({ isAnimated, textWhite }) =>
      isAnimated && !textWhite ? "#1d1d1f" : "#f5f5f7"};
    transition: all 0.4s ease-in-out;
  }
  &:before {
    content: "SignUp";
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 0;
    font-weight: bold;
    background-color: #fa5c40;
    color: #f5f5f7;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
  }
  &:hover:before {
    transform: translateY(-50px);
  }
  &:hover:after {
    transform: translateY(-50px);
  }
`;
