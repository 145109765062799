import React from "react";
import { styled } from "styled-components";

export default function SimpleButton({ text }) {
  return <Button>{text}</Button>;
}

const Button = styled.button`
  outline: none;
  min-width: 150px;
  height: 50px;
  border: 2px solid #f5f5f7;
  border-radius: 10px;
  color: #f5f5f7;
  font-weight: 600;
  background-color: #fa5c40;
`;
