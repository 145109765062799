import { motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";
import Partners from "../../components/Partners/partners";
import AboutPageCard from "../../components/Card/aboutPageCard";
import bgJournal from "../../assets/bgcardJournal.jpg";
import bgPlans from "../../assets/business-plan.jpg";
import SEO from "../../components/HeadSEOComponent/HeadSEO";
export default function Aboutus({ screen }) {
  return (
    <>
      <SEO
        title="Company | DelCargo"
        description="DelCargo: Welcome to our cargo and logistics company, where we specialize in providing efficient and reliable transportation solutions. With a dedicated team and advanced technology, we ensure seamless operations and timely deliveries. Trust us for all your cargo needs."
      />
      <Wrapper>
        <div className="heading-div">
          <h1>Delivering Smiles Worldwide</h1>
          <h2>DELCARGO</h2>
        </div>
        <div className="heading-div">
          <h1>Our Mission</h1>
          <p>
            At DelCargo Company, our mission is to empower customers worldwide,
            eradicating concerns over shipping restrictions. Every package we
            send is handled with care and a passion for providing exceptional
            service. We aim to be the reason behind your smile when you open a
            love-filled box from DelCargo.
          </p>
        </div>
        <AboutPageCard
          desc1={[
            "Unleash Your Buying Potential:",
            "Embrace Benefits, Evade Taxes!",
          ]}
          desc2="DelCargo: Your go-to shopping and shipping service! No more waiting for family visits. Tax-free shipping, US address, consolidation, and fast delivery. Save time and money!"
          btnHeading="Plans"
          btnLink="plans"
          cardimage={bgPlans}
        />
        <AboutPageCard
          desc1={["Beyond Deliveries: ", "Chronicles of Global Logistics"]}
          desc2="Inked logistics tales: Unveiling the journal section of DelCargo, where we transport stories across borders."
          btnHeading="Journal"
          btnLink="journal"
          cardimage={bgJournal}
        />
        <Partners screen={screen} />
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
  }

  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    list-style: square;
    padding-left: 100px;
    @media (max-width: 800px) {
      padding-left: 15px;
    }
    li {
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
    }
  }

  .heading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;

    height: auto;
    padding: 50px;
    @media (max-width: 800px) {
      padding: 10px;
      height: 350px;
      min-height: 70vh;
      height: auto;
    }

    p {
      text-align: center;
      font-size: 30px;
      padding: 50px 100px 100px 100px;
      @media (max-width: 800px) {
        padding: 10px;
      }
    }
    h2 {
      font-size: 25px;
      font-weight: 400;
      color: #1d1d1f;
    }
    h1 {
      font-size: 50px;
      font-weight: 600;
      color: #1d1d1f;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 40px;
        font-weight: 500;
      }
    }
  }
`;
