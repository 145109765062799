import React, { useState } from "react";
import { styled } from "styled-components";
import FloatingLabelInput from "../../components/Inputs/FloatingLabelInput";
import SelectCountry from "../../components/Inputs/selectCountry";

export default function SignupForm({ setFormData, formData }) {
  const [isValidEmail, setIsValidEmail] = useState(true);

  function validateEmail(email) {
    // Regular expression pattern for email validation
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the pattern
    return pattern.test(email);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "email") {
      // Perform email validation
      const isValidEmail = validateEmail(value);

      // Pass the email and validation result to the parent component
      setIsValidEmail(formData.email === "" || isValidEmail);
    }
  };

  /////// for   Email

  return (
    <Wrapper>
      <div className="personal-details-div">
        <h1>Personal Details</h1>
        <InputWrapper>
          <FloatingLabelInput
            type="text"
            label="First Name"
            value={formData.firstName}
            inputName="firstName"
            required={true}
            handleChange={handleChange}
          />
          <FloatingLabelInput
            type="text"
            label="Last Name"
            value={formData.lastName}
            inputName="lastName"
            required={true}
            handleChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper>
          <FloatingLabelInput
            type="email"
            label="Email"
            value={formData.email}
            inputName="email"
            required={true}
            isValidEmail={isValidEmail}
            email={formData.email}
            validation={true}
            handleChange={handleChange}
          />
          <FloatingLabelInput
            type="text"
            label="Ph. Number"
            value={formData.phNumber}
            inputName="phNumber"
            required={true}
            handleChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper>
          <FloatingLabelInput
            type="password"
            label="Password"
            value={formData.password}
            inputName="password"
            required={true}
            handleChange={handleChange}
          />
          <FloatingLabelInput
            type="password"
            label="Confirm Password"
            value={formData.confirmPassword}
            inputName="confirmPassword"
            required={true}
            handleChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper>
          <select value={formData.gender} name="gender" onChange={handleChange}>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>

          <input
            type="date"
            id="birthday"
            name="birthDay"
            value={formData.birthDay}
            onChange={handleChange}
          ></input>
        </InputWrapper>
      </div>
      <div className="shipping-details-div">
        <h1>Shipping Details</h1>
        <InputWrapper>
          <FloatingLabelInput
            type="text"
            label="Shipping Address"
            value={formData.shippingAddress}
            inputName="shippingAddress"
            required={true}
            handleChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper>
          <FloatingLabelInput
            type="text"
            label="ZIP Code"
            value={formData.zipCode}
            inputName="zipCode"
            required={true}
            handleChange={handleChange}
          />
          <FloatingLabelInput
            type="text"
            label="City"
            value={formData.city}
            inputName="city"
            required={true}
            handleChange={handleChange}
          />
          <FloatingLabelInput
            type="text"
            label="State"
            value={formData.state}
            inputName="state"
            required={true}
            handleChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper>
          {/* /////// input contry */}
          <SelectCountry value={formData.country} handleChange={handleChange} />
          {/* ///////////// */}
          <FloatingLabelInput
            type="text"
            label="Receiver's Number"
            value={formData.receiverNumber}
            inputName="receiverNumber"
            required={true}
            handleChange={handleChange}
          />
        </InputWrapper>
      </div>
    </Wrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  width: 100%;
  min-height: 75px;
  height: auto;
  padding: 5px;
  gap: 10px;
  #country {
    margin-top: 5px;
  }
  select {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid rgba(119, 119, 119, 0.6);
    outline: none;
    color: #999;
    background: transparent;

    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;

    &:focus {
      border: 1px solid black;
    }
  }
  select::-ms-expand {
    display: none;
  }
  #birthday {
    font-weight: 400;
    color: #999;
    padding-left: 40px;
    position: relative;

    justify-content: start;
    @media (max-width: 800px) {
      &:before {
        content: "mm/dd/yyyy";
        position: absolute;
      }
    }
  }
  input {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid rgba(119, 119, 119, 0.6);
    outline: none;
    color: black;
    font-weight: 600;

    &:focus {
      border: 1px solid black;
      font-weight: 400;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
  justify-content: center;
  align-items: start;
  width: 100%;
  height: 100%;

  .personal-details-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h1 {
      font-weight: 400;
      font-size: 22px;
      justify-content: start;
      width: 100%;
      display: flex;
      padding-left: 5px;
    }
  }
  .shipping-details-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h1 {
      font-weight: 400;
      font-size: 22px;
      justify-content: start;
      width: 100%;
      display: flex;
      padding-left: 5px;
    }
  }
`;
