import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import VideoPlayer from "./VideoPlayer";
import video from "../../../assets/bg-video-benefits.mp4";
import videoMobile from "../../../assets/bg-video-benefits-mobile.mp4";
import { Scene } from "react-scrollmagic";
import { AnimatePresence, motion } from "framer-motion";
import VideoPlayerMobile from "./VideoPlayerMobile";
import transitionImage from "../../../assets/imagefortransition.png";
import transitionImageMobile from "../../../assets/imagefortransition-mobile.png";
export default function SectionBenefitsTransition({ screen }) {
  function mapRangeScale(input, rMin, rMax) {
    var domainMin = 0;
    var domainMax = 1;
    var rangeMin = rMin;
    var rangeMax = rMax;

    // Normalize the input to a value between 0 and 1
    var normalizedInput = (input - domainMin) / (domainMax - domainMin);

    // Map the normalized input to the desired range
    var mappedValue = normalizedInput * (rangeMax - rangeMin) + rangeMin;
    return mappedValue;
  }

  return (
    <Scene indicators={false} duration={150} triggerHook={1}>
      {(progress) => (
        <motion.div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",

            scale: mapRangeScale(progress, 0.87, 1),
            borderRadius: `${mapRangeScale(progress, 25, 0)}px`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <ContentFunction
              screen={screen}
              br={mapRangeScale(progress, 25, 0)}
            />
          </div>
        </motion.div>
      )}
    </Scene>
  );
}

function ContentFunction({ screen, br }) {
  function mapRangeD3(input, rMin, rMax) {
    var domainMin = 0;
    var domainMax = 0.3;
    var rangeMin = rMin;
    var rangeMax = rMax;

    // Normalize the input to a value between 0 and 1
    var normalizedInput = (input - domainMin) / (domainMax - domainMin);

    // Map the normalized input to the desired range
    var mappedValue = normalizedInput * (rangeMax - rangeMin) + rangeMin;
    if (mappedValue <= rangeMax) {
      return rangeMax;
    }
    return mappedValue;
  }

  function mapRangeVideo(input, rMin, rMax) {
    var domainMin = 0.35;
    var domainMax = 1;
    var rangeMin = rMin;
    var rangeMax = rMax;

    // Normalize the input to a value between 0 and 1
    var normalizedInput = (input - domainMin) / (domainMax - domainMin);

    // Map the normalized input to the desired range
    var mappedValue = normalizedInput * (rangeMax - rangeMin) + rangeMin;
    if (input >= domainMin) {
      return mappedValue;
    }
    return 0;
  }
  return (
    <Scene pin indicators={false} duration={600} triggerHook={0}>
      {(progress) => (
        <Wrapper screen={screen} progress={progress} br={br}>
          <div className="content-wrapper">
            <div className="video-div">
              {screen.width >= 800 && (
                <VideoPlayer
                  prog={mapRangeVideo(progress, 0, 1)}
                  url={video}
                  play={false}
                />
              )}
              {screen.width < 800 && (
                <VideoPlayerMobile
                  prog={mapRangeVideo(progress, 0, 1)}
                  url={videoMobile}
                />
              )}
            </div>
            <div className="content-div">
              <Carousel progress={mapRangeVideo(progress, 0, 1)} />
            </div>
          </div>
          <motion.div
            style={{
              height: `${mapRangeD3(progress, 100, 0)}vh`,
              backgroundAttachment: `${progress > 0 ? "fixed" : "scroll"}`,
            }}
            className="image-div"
          >
            {/* <img src={transitionImage} alt="tesla removed bg" /> */}
          </motion.div>
        </Wrapper>
      )}
    </Scene>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: ${({ br }) => `${br}px`};
  * {
    border-radius: ${({ br }) => `${br}px`};
  }
  .image-div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #dde5e8;
    flex-direction: column-reverse;
    top: 0;
    left: 0;

    background-image: ${({ screen }) =>
      screen.width < 800
        ? `url(${transitionImageMobile})`
        : `url(${transitionImage})`};
    background-size: cover;

    background-position: center;

    background-repeat: no-repeat;
    // img {
    //   width: 100vw;
    //   height: 100vh;
    // }
  }
  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    .video-div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .content-div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;

      background-color: rgba(119, 119, 119, 0.1);
    }
  }
`;

const Carousel = ({ progress }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    "Tax-Free Shipping Address",
    "60 – days of storage",
    "Consolidated Package",
    "Low shipping cost",
    "Assisted Purchase",
  ];
  const numSlides = slides.length;
  useEffect(() => {
    const index = Math.floor(progress * numSlides);
    setCurrentIndex(index);
  }, [progress, numSlides]);

  return (
    <>
      <Card>
        <AnimatePresence>
          <motion.div
            className="slide"
            key={slides[currentIndex]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            {slides[currentIndex]}
          </motion.div>
        </AnimatePresence>
      </Card>
    </>
  );
};
const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 50px;
    @media (max-width: 800px) {
      font-size: 20px;
    }
    color: #f5f5f7;

    padding: 10px;
  }
`;
