import React, { useEffect } from "react";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import Logo from "../Logo/logo";
export default function StartingAnimation({ complete, setComplete }) {
  useEffect(() => {
    setTimeout(() => {
      setComplete(true);
    }, 4200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const delay = 1;
  return (
    <Wrapper complete={complete}>
      <motion.div
        variants={{
          visible: {
            width: "25vw",
          },
          hidden: {
            width: "0vw",
          },
        }}
        initial="visible"
        animate="hidden"
        transition={{
          duration: 1,
          delay: 2.2 + delay,
        }}
        className="left-div"
      ></motion.div>
      <motion.div
        variants={{
          visible: {
            width: "25vw",
          },
          hidden: {
            width: "0vw",
          },
        }}
        initial="visible"
        animate="hidden"
        transition={{
          duration: 1,
          delay: 2.2 + delay,
        }}
        className="right-div"
      ></motion.div>
      <motion.div
        variants={{
          hidden: {
            height: "100%",
          },
          visible: {
            height: "0%",
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 1,
          delay: 2.2 + delay,
        }}
        className="div-1"
      ></motion.div>

      <motion.div
        variants={{
          hidden: {
            height: "0%",
            width: "50%",
            // transform: "rotateX(-.5turn)",
            // transform: "skewY(30deg)",
            backgroundColor: "black",
            opacity: 0.4,
          },
          visible: {
            height: "100%",
            width: "100%",
            // transform: "rotateX(0turn)",
            // transform: "skewY(0deg)",
            backgroundColor: "black",
            opacity: 0,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 1,
          delay: 2.2 + delay,
        }}
        className="div-2"
      ></motion.div>
      <motion.div
        variants={{
          hidden: {
            height: "100%",
          },
          visible: {
            height: "0%",
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 1,
          delay: 2.2 + delay,
        }}
        className="div-3"
      ></motion.div>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
          },
          visible: {
            opacity: 1,
          },
        }}
        initial="visible"
        animate="hidden"
        transition={{
          duration: 0.1,
          delay: 1.5 + delay,
        }}
        className="logo-animation-div"
      >
        <Logo startAnimation={true} delay={delay} />
      </motion.div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: ${({ complete }) => (complete ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: transparent;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  scale: 1.2;
  z-index: 100;
  .logo-animation-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f5f5f7;
  }
  .left-div {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 15vw;
    background-color: #f5f5f7;
    transform: skewY(3deg);
  }
  .right-div {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 15vw;
    background-color: #f5f5f7;
    transform: skewY(3deg);
  }
  .div-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f7;
    transform: skewY(3deg);
  }
  .div-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 70%;
    // height: 500vh;
    // border: 2px solid black;
    // background-color: transparent;
    transform: skewY(3deg);
  }
  .div-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f7;
    transform: skewY(3deg);
  }
`;
