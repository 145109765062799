import React, { useEffect } from "react";
import { styled } from "styled-components";
import bg from "../../../assets/bg-home.jpg";
import bgMobile from "../../../assets/bg-home-mobile.png";
import { motion, useAnimation, useScroll } from "framer-motion";

import PromoVideo from "../../../assets/promo-video.mp4";
import SmallVideoPlayer from "./SmallVideoPlayer";

export default function SectionOneHome({
  startingAnimationComplete,
  screen,
  setVideoURL,
}) {
  let { scrollY } = useScroll();
  let control = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollY.current >= 70) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY, control]);

  return (
    <Wrapper screen={screen}>
      <UpperDIV>
        <motion.div
          variants={{
            hidden: {
              marginBottom: `${screen.width < 800 ? "160px" : "20px"}`,
            },
            visible: {
              marginBottom: `${screen.width < 800 ? "20px" : "20px"}`,
            },
          }}
          initial="hidden"
          animate={control}
          transition={{
            duration: 0.4,
          }}
          className="content-div"
        >
          <div className="heading-div">
            <motion.h1
              variants={{
                hidden: {
                  opacity: 0,
                },
                visible: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="visible"
              transition={{
                duration: 1,
                delay: `${startingAnimationComplete ? 0.5 : 3.25}`,
              }}
            >
              Worldwide Delivery: <br /> Your Trusted Logistics Partner!
            </motion.h1>
            <div className="line-div">
              <span className="line">
                <motion.div
                  variants={{
                    hidden: {
                      opacity: 0,
                      width: 0,
                    },
                    visible: {
                      opacity: 1,
                      width: "60%",
                    },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    duration: 1,
                    delay: `${startingAnimationComplete ? 0.5 : 3.25}`,
                  }}
                ></motion.div>
              </span>
              <motion.span
                variants={{
                  hidden: {
                    opacity: 0,
                  },
                  visible: {
                    opacity: 1,
                  },
                }}
                initial="hidden"
                animate="visible"
                transition={{
                  duration: 1,
                  delay: `${startingAnimationComplete ? 0.5 : 3.25}`,
                }}
                className="text"
              >
                <div>Scroll to Explore</div> <div className="arrow">&gt;</div>
              </motion.span>
            </div>
          </div>

          <div className="video-div">
            <motion.div
              variants={{
                hidden: {
                  height: "0%",
                },
                visible: {
                  height: "100%",
                },
              }}
              initial="hidden"
              animate="visible"
              transition={{
                duration: 0.7,
                delay: `${startingAnimationComplete ? 0.5 : 3.25}`,
              }}
              className="innerVideo-div"
            >
              <SmallVideoPlayer url={PromoVideo} />
            </motion.div>
            <div className="play-btn" onClick={() => setVideoURL(PromoVideo)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="black"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="black"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                />
              </svg>
            </div>
          </div>
        </motion.div>
      </UpperDIV>
      <MobileLogo>DELCARGO</MobileLogo>
    </Wrapper>
  );
}

const MobileLogo = styled.div`
  @media (max-width: 800px) {
    opacity: 1;
  }
  opacity: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 30px;
  font-weight: 500;
  font-size: 25px;
  color: #f5f5f7;
`;

const UpperDIV = styled.div`
  background-color: rgba(119, 119, 119, 0.2);
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: end;

  .content-div {
    flex-direction: row;
    width: 100%;
    height: 40%;
    margin-bottom: 20px;
    @media (max-width: 800px) {
      width: 90%;
      height: 60%;
      justify-content: end;
      flex-direction: column;
      margin-bottom: 90px;
    }
    .heading-div {
      flex-direction: column;
      height: 100%;
      width: 100%;
      overflow: hidden;
      .line-div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100%;

        @media (max-width: 800px) {
          display: none;
        }
        .line {
          width: 100%;
          height: 100%;
          padding-left: 50px;
          justify-content: start;
          align-items: start;

          div {
            width: 65%;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.4);
            border: none;
          }
        }
        .text {
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          * {
            color: #f5f5f7;
          }

          flex-direction: column;
          .arrow {
            transform: rotate(90deg);
          }
        }
      }
      h1 {
        font-family: "GothamLight", -apple-system, BlinkMacSystemFont,
          avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell,
          Ubuntu, roboto, noto, arial, sans-serif;
        font-weight: 400;
        font-size: 45px;
        color: #f5f5f7;
        text-align: left;
        height: 100%;
        padding-left: 50px;
        padding-right: 50px;
        @media (max-width: 800px) {
          width: 100%;
          height: 100%;
          font-size: 25px;
          justify-content: center;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .video-div {
      height: 90%;
      width: 45%;

      @media (max-width: 800px) {
        width: 100%;
      }
      justify-content: end;
      align-items: end;
      margin-right: 50px;
      border-radius: 15px;

      .innerVideo-div {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        isolation: isolate;
        z-index: 3;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
      }
      .play-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        z-index: 4;
        border-radius: 100%;
        background-color: #f5f5f5;
        position: absolute;
        margin: 10px;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        &:hover {
          background-color: #fa5c40;
          svg {
            fill: #f5f5f7;
            stroke: #f5f5f7;
          }
        }
      }
    }
  }
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  * {
    display: flex;
    // justify-content: center;
    // align-items: center;
  }
  ///////////BACKGROUND IMAGE
  background-image: ${({ screen }) =>
    screen.width < 700 ? `url(${bgMobile})` : `url(${bg})`};
  background-size: cover;
  background-attachment: scroll;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;

  background-position: ${({ screen }) =>
    screen.width > 700 && screen.width < 1200 ? "-90px 0" : `end`};
  @media (max-width: 800px) {
    background-position: center;
  }
  background-repeat: no-repeat;
`;
