import { motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";
import SEO from "../../components/HeadSEOComponent/HeadSEO";

export default function ReturnPolicy({ screen }) {
  return (
    <>
      <SEO title="Return Policy | DelCargo" description="" />
      <Wrapper>
        <div className="heading-div">
          <h1>Return Policy</h1>
          <h2>DELCARGO</h2>
        </div>
        <div className="content-div">
          <h2>Package Delivery and Returns</h2>
          <p>
            Once your packages get delivered to the warehouse, the DelCargo
            staff will send you a few HD photos of the merchandise so you can be
            sure of what has been delivered. If the delivered merchandise is not
            what you had ordered, or the quality does not match your
            expectations, or it got damaged during the shipment process, you can
            simply request a return.
          </p>
          <p>
            <b>DelCargo will make a return on your behalf</b>. To initiate a
            return, you just need to provide the return label. DelCargo
            generates return labels once you finally choose to ship with us. By
            using the label provided to you by DelCargo, you'll be charged{" "}
            <b>$5/package service charges</b> as per our return policy.
          </p>
          <p>
            <b>
              DelCargo's return label is automatically made available to
              customers
            </b>{" "}
            who use the assisted purchase feature.
          </p>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
  }

  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    list-style: square;
    padding-left: 100px;
    @media (max-width: 800px) {
      padding-left: 15px;
    }
    li {
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
    }
  }

  .content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 70vw;

    @media (max-width: 800px) {
      width: 100vw;
      padding: 20px;
      * {
        text-align: left;
      }
    }
    height: auto;

    h2 {
      font-size: 40px;
      font-weight: 500;
      padding-bottom: 20px;
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (max-width: 800px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .heading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 80vh;

    padding: 50px;
    @media (max-width: 800px) {
      padding: 10px;
      height: 350px;
    }
    h2 {
      font-size: 30px;
      font-weight: 400;
      color: #1d1d1f;
    }
    h1 {
      font-size: 60px;
      font-weight: 600;
      color: #1d1d1f;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 50px;
        font-weight: 500;
      }
    }
  }
`;
