import React from "react";
import { styled } from "styled-components";
import PlansComponent from "../../components/Plans/planCards";
import Partners from "../../components/Partners/partners";
import SEO from "../../components/HeadSEOComponent/HeadSEO";

export default function Plans({ screen }) {
  return (
    <>
      <SEO
        title="Plans | DelCargo"
        description="Discover the Plans given by DelCargo"
      />
      <Wrapper>
        <div className="heading-div">
          <h1>Plans</h1>
          <h2>Unleash Your Buying Potential: Embrace Benefits, Evade Taxes!</h2>
        </div>
        <div className="cards-div">
          <PlansComponent forSignup={false} />
        </div>
        <Partners screen={screen} />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
  }

  .heading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 60vh;
    text-align: center;
    padding: 50px;
    @media (max-width: 800px) {
      padding: 10px;
      height: 350px;
    }
    h2 {
      font-size: 30px;
      font-weight: 400;
      color: #1d1d1f;
      text-align: center;
    }
    h1 {
      font-size: 60px;
      font-weight: 600;
      color: #1d1d1f;

      text-align: center;
      @media (max-width: 800px) {
        font-size: 50px;
        font-weight: 500;
      }
    }
  }
`;
