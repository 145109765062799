import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
const listBasic = [
  "Tax Free US Address",
  "Free Consolidation",
  "30 Days Storage",
  "Equipment Testing - $5",
  "Detailed Item Photo - $2",
  "Private Address - 5% of Item Cost",
  "Insurance - $10",
  "30 Days Free Storage",

  "Shipment Cancellation - $5",
];
const listPremium = [
  "Tax Free US Address",
  "Same Day Shipping",
  "Free Consolidation",
  "Unlimited Days Storage",
  "Equipment Testing - $5",
  "Detailed Item Photo - $2",
  "Private Address - 5% of Item Cost",
  "Unlimited Cashback - 3%",
  "Insurance - $10 or 5%",
  "Shipment Cancellation - $3",
];

const listBusiness = [];
export default function PlansComponent({ nextStep, forSignup }) {
  return (
    <Wrapper>
      <div className="basic">
        <Card
          nextStep={nextStep}
          heading="Basic"
          desc="Multiple store shipping solution!"
          list={listBasic}
          price="Free"
          forSignup={forSignup}
        />
      </div>
      <div className="premium">
        <Card
          nextStep={nextStep}
          heading="Premium"
          desc="Effortless shipping with 1% cashback and 180 days storage time."
          list={listPremium}
          price="$9.99"
          forSignup={forSignup}
        />
      </div>
      <div className="business">
        <Card
          nextStep={nextStep}
          heading="Business"
          desc="Having a high volume of shipments? Don't worry!"
          list={listBusiness}
          price="Contact Us"
          forSignup={forSignup}
        />
      </div>
    </Wrapper>
  );
}

function Card({ heading, desc, price, list, nextStep, forSignup }) {
  let navigate = useNavigate();
  return (
    <CardWrapper>
      <div className="heading-desc-div">
        <h1>{heading}</h1>
        <p>{desc}</p>
        <h2>{price}</h2>
      </div>
      <div className="button-div">
        {forSignup ? (
          <button onClick={() => nextStep()}>Choose {heading}</button>
        ) : (
          <button onClick={() => navigate("/register")}>SignUP</button>
        )}
      </div>
      <div className="list-div">
        {list.map((d) => (
          <li className="list-item">{d}</li>
        ))}
      </div>
    </CardWrapper>
  );
}
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 15px;
  @media (max-width: 800px) {
    padding: 0px;
  }
  justify-content: center;
  align-items: center;

  * {
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
  }
  .heading-desc-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    h1 {
      display: flex;
      font-weight: 600;
      font-size: 40px;
      color: #1d1d1f;
    }
    p {
      display: flex;
      font-size: 18px;
      color: #1d1d1f;
      padding: 8px 0px 8px 0px;
      min-height: 100px;
    }
    h2 {
      display: flex;
      font-weight: 600;
      font-size: 35px;
      color: #1d1d1f;
    }
  }
  .button-div {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px;
    button {
      display: flex;
      padding: 10px;
      width: 150px;
      height: 50px;
      border-radius: 15px;
      background: #1d1d1f;
      color: #f5f5f7;
      border: none;
      font-weight: bold;
      outline: none;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: #fa5c40;
      }
    }
  }
  .list-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: start;
    padding: 10px;

    li {
      display: flex;
      text-align: left;
      width: 100%;
      padding: 10px 10px 10px 25px;
      justify-content: left;
      list-style-type: square;
      position: relative;
      color: #1d1d1f;
      &:before {
        content: "";
        display: flex;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        position: absolute;
        background-color: rgba(119, 119, 119, 0.2);
      }
    }
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  gap: 15px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  width: 100%;
  height: 100%;
  //   * {
  //     border: 1px solid black;
  //   }
  .basic {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: start;
    align-items: center;
  }
  .premium {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: start;
    align-items: center;
  }
  .business {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: start;
    align-items: center;
  }
`;
