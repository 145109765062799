import { motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";
import SEO from "../../components/HeadSEOComponent/HeadSEO";

export default function PaymentAndTax({ screen }) {
  return (
    <>
      <SEO title="Payment & Tax | DelCargo" description="" />
      <Wrapper>
        <div className="heading-div">
          <h1>Payment & Tax</h1>
          <h2>DELCARGO</h2>
        </div>
        <div className="content-div">
          <h2>Taxes and Duties</h2>
          <p>
            Imports are subjected to certain percentages of taxes and customs
            duties. These percentages are determined by the customs department
            of the government of each country. Each country has a different
            system for applying the taxes and customs. Some countries implement
            Goods and Services Tax (GST), and others apply Value Added Tax (VAT)
            on the total value of imports. Customs applies a specified chunk of
            special duty tax, also known as tariff, for importing international
            goods across the border of a country.
          </p>
          <p>
            <b>Taxes and custom duties have nothing to do with the company</b>;
            they straightaway go to the customs department of your respective
            country. Once your packages arrive at customs, you'll be notified.
            You'll have to pay the tax/duty in order to get your shipment
            released from the tariff department and to get it delivered to your
            doorstep. The rest of the legal documentation regarding import
            formalities is completed by DelCargo itself.
          </p>
          <h2>Payments</h2>
          <p>
            DelCargo works on creating ease in international shipping rather
            than making it more complex. Different payment methods are widely
            accepted at DelCargo:
          </p>
          <ul>
            <li>
              <b>Credit card</b>
            </li>
            <li>
              <b>PayPal</b>
            </li>
            <li>
              <b>Wire transfer</b>
            </li>
            <li>
              <b>ACH</b>
            </li>
            <li>
              <b>Zelle</b>
            </li>
            <li>
              <b>Apple Pay</b>
            </li>
            <li>
              <b>Cash App</b>
            </li>
          </ul>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
  }

  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    list-style: square;
    padding-left: 100px;
    @media (max-width: 800px) {
      padding-left: 15px;
    }
    li {
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
    }
  }

  .content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 70vw;

    @media (max-width: 800px) {
      width: 100vw;
      padding: 20px;
      * {
        text-align: left;
      }
    }
    height: auto;

    h2 {
      font-size: 40px;
      font-weight: 500;
      padding-bottom: 20px;
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (max-width: 800px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .heading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 80vh;

    padding: 50px;
    @media (max-width: 800px) {
      padding: 10px;
      height: 350px;
    }
    h2 {
      font-size: 30px;
      font-weight: 400;
      color: #1d1d1f;
    }
    h1 {
      font-size: 60px;
      font-weight: 600;
      color: #1d1d1f;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 50px;
        font-weight: 500;
      }
    }
  }
`;
