import React from "react";
import { styled } from "styled-components";

export default function AnimatedCard2Mobile({
  heading,
  text,
  bgColor,
  bgPic,
  onClick,
}) {
  return (
    <>
      <Wrapper bgPic={bgPic} bgColor={bgColor} onClick={onClick}>
        <div className="div-image"></div>
        <div className="heading-text-icon">
          <div className="heading">
            <h1>
              Explore &nbsp; {heading[0]}
              <br />
              {heading[1]}
            </h1>
          </div>
          <div className="text">
            <p>
              {text[0]}
              <br /> {text[1]}
            </p>
          </div>
          <div className="icon">
            <span className="inner-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </span>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 15px;
  * {
    border-radius: 15px;
  }

  cursor: pointer;
  transition: all 0.3s ease-in-out;
  .div-image {
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%;
    height: 220px;
    background-image: ${({ bgPic }) => `url(${bgPic})`};
    background-size: cover;

    background-position: center;

    background-repeat: no-repeat;
  }
  .heading-text-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 320px;
    padding: 25px;
    gap: 20px;
    position: relative;
    overflow: hidden;
    background-color: ${({ bgColor }) => bgColor};
    .icon {
      display: flex;

      justify-content: start;
      align-items: center;
      width: 100%;

      z-index: 2;
      .inner-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background-color: #f5f5f7;
      }
    }
    .text {
      display: flex;
      z-index: 2;
      justify-content: start;
      align-items: center;
      width: 100%;

      p {
        color: white;
        font-weight: 400;
        font-size: 20px;
      }
    }

    .heading {
      display: flex;
      z-index: 2;
      justify-content: start;
      align-items: center;
      width: 100%;

      h1 {
        color: white;
        font-weight: 400;
        font-size: 30px;
      }
    }
  }
`;
