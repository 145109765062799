import { motion, useAnimation, useScroll } from "framer-motion";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

export default function CustomHamburger({
  menuOpen,
  blackHamburger,
  setMenuOpen,
}) {
  const { scrollYProgress, scrollY } = useScroll();
  let [isAnimated, setIsAnimated] = useState(false);
  const animationControls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollY.current >= 100) {
        animationControls.start("visible");
        setIsAnimated(true);
      } else {
        animationControls.start("hidden");
        setIsAnimated(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollYProgress, animationControls, scrollY]);
  return (
    <Wrapper
      blackHamburger={blackHamburger}
      onClick={() => setMenuOpen(!menuOpen)}
      menuOpen={menuOpen}
      isAnimated={isAnimated}
      variants={{
        hidden: {
          borderRadius: "100%",
        },
        visible: {
          borderRadius: "100%",
        },
      }}
      initial="hidden"
      animate={animationControls}
      transition={{ duration: 0.1 }}
    >
      <span className="line1"></span>

      <span className="line2"></span>

      <span className="hover-line1"></span>
      <span className="hover-line2"></span>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  border: ${({ isAnimated, menuOpen, blackHamburger }) =>
    isAnimated || menuOpen || blackHamburger
      ? "2px solid #1d1d1f"
      : "2px solid #f5f5f7"};

  @media (max-width: 800px) {
    border: none;
  }
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease-in-out;
  * {
    transition: all 0.3s ease-in-out;
  }
  &:before {
    @media (max-width: 800px) {
      display: none;
    }
    content: "";
    display: ${({ blackHamburger, menuOpen }) =>
      blackHamburger || menuOpen ? "none" : "flex"};
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    scale: 0;
    background: ${({ isAnimated }) => (isAnimated ? "#1d1d1f" : "#f5f5f7")};
    border: ${({ isAnimated }) =>
      isAnimated ? "2px solid #1d1d1f" : "2px solid #f5f5f7"};
    border-radius: 100%;
    transition: all 0.3s ease-in-out;
  }
  &:hover::before {
    scale: ${({ menuOpen }) => (menuOpen ? "0" : "1")};
  }
  &:hover {
    // border: none;
    .line1 {
      display: none;
    }
    .line2 {
      display: none;
    }
  }

  * {
    //     border: 1px solid #1d1d1f;
    padding: 0;
    margin: 0;
  }

  .line1 {
    border-radius: 10px;
    display: ${({ menuOpen }) => (menuOpen ? "none" : "flex")};
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    height: 2px;
    background: ${({ isAnimated, menuOpen, blackHamburger }) =>
      isAnimated || menuOpen || blackHamburger ? "#1d1d1f" : "#f5f5f7"};
    @media (max-width: 800px) {
      background: #1d1d1f;
      width: 55%;
    }
    position: absolute;
    transform: ${({ menuOpen }) =>
        menuOpen ? "translateY(0px)" : "translateY(3.5px)"}
      ${({ menuOpen }) => (menuOpen ? "rotate(45deg)" : "rotate(0deg)")};

    width: ${({ menuOpen }) => (menuOpen ? "60%" : "70%")};
  }

  .line2 {
    border-radius: 10px;
    display: ${({ menuOpen }) => (menuOpen ? "none" : "flex")};
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    height: 2px;
    background: ${({ isAnimated, menuOpen, blackHamburger }) =>
      isAnimated || menuOpen || blackHamburger ? "#1d1d1f" : "#f5f5f7"};
    @media (max-width: 800px) {
      background: #1d1d1f;
      width: 55%;
    }
    position: absolute;
    transform: ${({ menuOpen }) =>
        menuOpen ? "translateY(0px)" : "translateY(-3.5px)"}
      ${({ menuOpen }) => (menuOpen ? "rotate(-45deg)" : "rotate(0deg)")};

    width: ${({ menuOpen }) => (menuOpen ? "60%" : "70%")};
  }

  ///////

  .hover-line1 {
    border-radius: 10px;
    display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
      background: #1d1d1f;
      width: 55%;
    }
    height: 2px;
    background: ${({ isAnimated, menuOpen }) =>
      isAnimated && !menuOpen ? "#f5f5f7" : "#1d1d1f"};
    position: absolute;
    transform: ${({ menuOpen }) =>
        menuOpen ? "translateY(0px)" : "translateY(3.5px)"}
      ${({ menuOpen }) => (menuOpen ? "rotate(45deg)" : "rotate(0deg)")};

    width: ${({ menuOpen }) => (menuOpen ? "60%" : "70%")};
  }

  .hover-line2 {
    border-radius: 10px;
    display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
      background: #1d1d1f;
      width: 55%;
    }
    height: 2px;
    background: ${({ isAnimated, menuOpen }) =>
      isAnimated && !menuOpen ? "#f5f5f7" : "#1d1d1f"};
    position: absolute;
    transform: ${({ menuOpen }) =>
        menuOpen ? "translateY(0px)" : "translateY(-3.5px)"}
      ${({ menuOpen }) => (menuOpen ? "rotate(-45deg)" : "rotate(0deg)")};

    width: ${({ menuOpen }) => (menuOpen ? "60%" : "70%")};
  }

  &:hover {
    .hover-line1 {
      display: ${({ menuOpen }) => (menuOpen ? "flex" : "flex")};
    }
    .hover-line2 {
      display: ${({ menuOpen }) => (menuOpen ? "flex" : "flex")};
    }
  }
`;
