import React from "react";
import { styled } from "styled-components";
import ArticleCard from "../../components/Card/articleCard";
import { articles } from "./writtenArticles";
import { useNavigate } from "react-router-dom";
import SEO from "../../components/HeadSEOComponent/HeadSEO";
export default function Journal({ screen }) {
  let navigate = useNavigate();
  return (
    <>
      <SEO
        title="Journal | DelCargo"
        description="Discover the driving force empowering our team, our solutions, and a greener future for logistics. Or, gather inspiration for your next shipping journey."
      />
      <Wrapper>
        <div className="heading-desc-count-div">
          <div className="heading-desc-div">
            <div className="heading-div">
              <h1>Journal</h1>
            </div>
            <div className="desc-div">
              <div className="c-div">&copy; {new Date().getFullYear()}</div>
              <div className="desc">
                <p>
                  This is your realm to navigate. Discover the driving force
                  empowering our team, our solutions, and a greener future for
                  logistics. Or, gather inspiration for your next shipping
                  journey.
                </p>
              </div>
            </div>
          </div>
          <div className="count-div">
            <span>{articles.length}</span>
          </div>
        </div>
        <div className="articles-div">
          <div className="grid">
            {articles.reverse().map((d, i) => (
              <div
                className="article-link"
                onClick={() => navigate(`/journal/${d.path}`)}
              >
                <ArticleCard
                  heading={d.title}
                  date={d.date}
                  titleImage={d.image}
                />
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    justify-content: start;
    margin-top: 60px;
  }
  width: 100vw;
  min-height: 100vh;
  height: auto;
  margin-top: 80px;
  * {
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .articles-div {
    min-height: 100vh;
    height: auto;
    padding: 50px;
    @media (max-width: 800px) {
      padding: 20px;
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      @media (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .heading-desc-count-div {
    flex-direction: row;
    @media (max-width: 800px) {
      flex-direction: column;
      height: 50vh;
    }

    width: 100vw;
    height: 80vh;

    .heading-desc-div {
      flex-direction: column;
      .heading-div {
        h1 {
          font-size: 65px;
          @media (max-width: 800px) {
            font-size: 60px;
          }
        }
      }
      .desc-div {
        flex-direction: row;
        .desc {
          padding: 10px;
          p {
            text-align: center;
            line-height: 1.5;
          }
        }
        .c-div {
          width: 250px;
          @media (max-width: 800px) {
            display: none;
          }
        }
      }
    }

    .count-div {
      justify-content: end;
      padding: 0px 50px 0px 0px;
      @media (max-width: 800px) {
        display: none;
      }
      span {
        font-family: "Suisse", Menlo, Consolas, Monaco, Liberation Mono,
          Lucida Console, monospace;
        height: 100%;
        width: 250px;
        overflow: hidden;
      }
      font-weight: 500;
      font-size: 380px;
    }
  }
`;
