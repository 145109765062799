import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

import { motion, useAnimation, useScroll } from "framer-motion";

import { Link, useLocation, useNavigate } from "react-router-dom";
import NavBarLogo from "../Logo/NavBarLogo";
import LoginButton from "../Button/LoginBtn";
import CustomHamburger from "../hambuger/customHamburger";
export default function NavBar({ screen, progress, menuOpen, setMenuOpen }) {
  const [hideLoginBtn, setHideLoginBtn] = useState(false);
  let [isAnimated, setIsAnimated] = useState(false);
  const [blackHamburger, setBlackHamburger] = useState(false);
  let navigate = useNavigate();

  let location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/payment-and-tax" ||
      location.pathname === "/return-policy" ||
      location.pathname === "/terms-of-service" ||
      location.pathname === "/privacy-policy" ||
      location.pathname === "/register" ||
      location.pathname === "/plans" ||
      location.pathname === "/journal" ||
      location.pathname === "/faqs" ||
      location.pathname.includes("journal") ||
      location.pathname === "/company" ||
      location.pathname === "/solutions"
    ) {
      setBlackHamburger(true);
    } else {
      setBlackHamburger(false);
    }

    if (location.pathname === "/login") {
      setHideLoginBtn(true);
    } else {
      setHideLoginBtn(false);
    }
  }, [location]);

  const { scrollYProgress, scrollY } = useScroll();
  const animationControls = useAnimation();
  const logoControlAnuimation = useAnimation();
  useEffect(() => {
    const handleScroll = () => {
      if (scrollY.current >= 100) {
        animationControls.start("visible");
        logoControlAnuimation.start("visible");
        setIsAnimated(true);
      } else {
        animationControls.start("hidden");
        logoControlAnuimation.start("hidden");
        setIsAnimated(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollYProgress, animationControls, scrollY, logoControlAnuimation]);

  return (
    <>
      {screen.width > 800 && (
        <WrapperDIV
          variants={{
            hidden: {
              opacity: 0,
              scale: 1.2,
            },
            visible: {
              opacity: 1,
              scale: 1,
            },
          }}
          initial="hidden"
          animate={animationControls}
          transition={{ duration: 0.2 }}
        ></WrapperDIV>
      )}

      <Nav
        blackHamburger={blackHamburger}
        screen={screen}
        isAnimated={isAnimated}
      >
        <div className="hamburger-div">
          <CustomHamburger
            blackHamburger={blackHamburger}
            menuOpen={menuOpen}
            setHideLoginBtn={setHideLoginBtn}
            setMenuOpen={setMenuOpen}
          />
          {menuOpen ? (
            <p
              style={{
                color: "#1d1d1f",
              }}
            >
              Close
            </p>
          ) : (
            <>{screen.width >= 400 && <p>Menu</p>}</>
          )}
        </div>
        <div className="logo-div" onClick={() => navigate("/")}>
          <NavBarLogo screen={screen} progress={progress} />
        </div>
        <div className="button-div">
          {" "}
          <Link to="/login">
            {!hideLoginBtn && (
              <LoginButton
                screen={screen}
                textWhite={false}
                btnWidth={"150px"}
                btnHeight={"50px"}
                menuOpen={menuOpen}
              />
            )}
          </Link>
        </div>
      </Nav>
    </>
  );
}

const Nav = styled(motion.div)`
  position: fixed;
  width: 90%;
  height: 70px;

  z-index: 10;
  display: flex;
  flex-direction: row;

  @media (min-width: 800px) {
    top: 20px;
  }
  @media (max-width: 800px) {
    bottom: 20px;
    background: rgba(255, 255, 255, 0.31);
    height: 56px;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .hamburger-div {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: left;
    padding-left: 10px;
    z-index: 10;

    p {
      display: flex;
      color: ${({ isAnimated, blackHamburger }) =>
        isAnimated || blackHamburger ? "#1d1d1f" : "#f5f5f7"};
      width: auto;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      @media (max-width: 800px) {
        color: #1d1d1f;
      }
    }
  }

  .logo-div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1d1d1f;

    width: 100%;
    overflow: visible;
    cursor: pointer;
    height: 100%;

    z-index: 10;
    @media (max-width: 800px) {
      width: 100%;
      // padding-right: 5px;
    }
  }
  .button-div {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    z-index: 2;
  }
`;

const WrapperDIV = styled(motion.div)`
  position: fixed;
  width: 90%;
  height: 70px;
  z-index: 5;
  @media (min-width: 800px) {
    top: 20px;
  }
  @media (max-width: 800px) {
    bottom: 20px;
    height: 56px;
  }

  background: rgba(255, 255, 255, 0.31);

  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: row;
`;
