import React from "react";
import { styled } from "styled-components";
import ArticleCard from "../../../components/Card/articleCard";

export default function SectionUpComingEvents({ screen }) {
  const events = [
    {
      title: "GITEX Global",

      date: "16.10.2023",
      image:
        "https://pbs.twimg.com/profile_images/1539663802479812608/pn4N8_i9_400x400.jpg",
      path: "https://www.gitex.com/",
    },
    {
      title: "Consumer Technology Association",

      date: "09.12.2024",
      image: "https://bulldogawards.com/wp-content/uploads/2016/08/CTA.jpg",
      path: "https://www.ces.tech/",
    },
  ];

  return (
    <Wrapper>
      <div className="heading-div">
        <div className="heading">
          <h1>UpComing Events</h1>
          <span></span>
        </div>
      </div>
      <div className="articles-div">
        {events.map((d, i) => (
          <a
            key={i}
            className="article-link"
            href={d.path}
            rel="noreferrer"
            target="_blank"
          >
            <ArticleCard heading={d.title} date={d.date} titleImage={d.image} />
          </a>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  gap: 10px;
  padding: 50px;
  scroll-behavior: smooth;
  overflow: hidden;
  width: 100vw;

  @media (max-width: 800px) {
    padding: 10px;
  }
  .heading-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90px;
    padding: 15px;
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
      padding: 10px;
    }
    .heading {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: start;
      align-items: center;
      h1 {
        font-size: 30px;
        font-weight: 500;
        padding: 5px;
        justify-content: center;
        align-items: center;
        @media (max-width: 800px) {
          font-size: 20px;
        }
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        padding-left: 10px;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .articles-div {
    display: flex;

    flex-direction: row;
    overflow-x: auto;
    gap: 15px;
    scroll-behavior: smooth;
    width: 95vw;
    .article-link {
      display: flex;

      justify-content: center;
      align-items: center;
      width: 330px;
      height: 400px;
      flex-shrink: 0;
      @media (max-width: 800px) {
        width: 80%;
      }
    }
  }

  /* Width and height of the scroll bar track */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Styles for the thumb (the draggable scrolling element) */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  /* Styles for the thumb when hovered */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* Styles for the track (the empty space not occupied by the thumb) */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 4px;
  }

  /* Styles for the track when the thumb is hovered */
  ::-webkit-scrollbar-track:hover {
    background-color: #eee;
  }
`;
