import React from "react";
import { styled } from "styled-components";

import HoverButton from "../Button/button";
export default function AnimatedCardButton({
  textSize,
  bgPic,
  BtnWidth,
  BtnHeight,
  onClick,
  BorderRadius,
}) {
  return (
    <Wrapper
      style={{
        borderTopLeftRadius: BorderRadius[0],
        borderBottomLeftRadius: BorderRadius[3],
      }}
      bgPic={bgPic}
    >
      <div
        className="image"
        style={{
          borderTopLeftRadius: BorderRadius[0],
          borderBottomLeftRadius: BorderRadius[3],
        }}
      >
        <div
          className="cover"
          style={{
            borderTopLeftRadius: BorderRadius[0],
            borderBottomLeftRadius: BorderRadius[3],
          }}
        >
          <HoverButton
            textWhite={true}
            textSize={textSize}
            onClick={onClick}
            btnHeight={BtnHeight}
            btnWidth={BtnWidth}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 20px;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-image: ${({ bgPic }) => `url(${bgPic})`};
    background-size: cover;

    background-position: center;
    scale: 1;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      scale: 1.01;
    }
    .cover {
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: end;
      padding-bottom: 25px;
      width: 100%;
      height: 100%;
      background: transparent;
    }
  }
`;
