import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";

import PromoVideo from "../../assets/promo-video.mp4";

import cardBG from "../../assets/cardBG.png";
import AnimatedCardButton from "../Card/AnimatedCardButton";
import { useNavigate } from "react-router-dom";
import SmallVideoPlayer from "../../pages/Home/HomeComponents/SmallVideoPlayer";
export default function MenuDesktop({
  screen,
  setVideoURL,
  setMenuOpen,
  menuOpen,
}) {
  let navigate = useNavigate();

  function navNclose(route) {
    navigate(`/${route}`);
    setMenuOpen(false);
  }
  return (
    <>
      <AnimatePresence>
        {menuOpen && (
          <Wrapper
            key="modal"
            variants={{
              hidden: {
                opacity: 0,
                height: "0vh",
              },
              visible: {
                opacity: 1,
                height: "100vh",
              },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{
              duration: 0.5,
            }}
          >
            <div className="menu">
              <div className="div-1">
                <div className="empty-div"></div>
                <div className="links-div">
                  <div className="useful-links">
                    <li onClick={() => navNclose("return-policy")}>
                      Return Policy
                    </li>
                    <li onClick={() => navNclose("payment-and-tax")}>
                      Payment & Tax
                    </li>
                    <li onClick={() => navNclose("terms-of-service")}>
                      Terms of Service
                    </li>

                    <li onClick={() => navNclose("privacy-policy")}>
                      Privacy Policy
                    </li>
                  </div>
                  <div className="social-links">
                    <li>Instagram</li>
                    <li>Facebook</li>
                    <li>LinkedIn</li>
                  </div>
                </div>
                <div className="video-div">
                  <div className="video">
                    <SmallVideoPlayer url={PromoVideo} />
                  </div>
                  <div
                    className="play-btn"
                    onClick={() => setVideoURL(PromoVideo)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="black"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="black"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="div-2">
                <li onClick={() => navNclose("")}>Home</li>
                <li onClick={() => navNclose("company")}>Company</li>
                <li onClick={() => navNclose("journal")}>Journal</li>
                <li onClick={() => navNclose("plans")}>Plans</li>
                <li onClick={() => navNclose("solutions")}>Solutions</li>
                <li onClick={() => navNclose("faqs")}>FAQS</li>
              </div>
              <div className="div-3">
                <div className="cardButton">
                  <AnimatedCardButton
                    onClick={() => navNclose("register")}
                    bgPic={cardBG}
                    BtnHeight={"50px"}
                    BtnWidth={"150px"}
                    BorderRadius={["", "", "", ""]}
                  />
                </div>
              </div>
            </div>
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
}

const Wrapper = styled(motion.section)`
  // * {
  //   border: 1px solid black;
  // }
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f7;
  position: fixed;

  top: 0;
  left: 0;
  .hamburger {
    position: absolute;
    top: 50px;
    left: 60px;
  }
  .logo {
    position: absolute;
    top: 50px;
    wdith: 280px;
    height: auto;
    img {
      width: 150px;
      height: auto;
    }
  }
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    background-color: #f5f5f7;
    position: relative;
    .div-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .empty-div {
        display: flex;
        width: 100%;
        height: 100%;
      }
      .links-div {
        * {
          font-weight: 400;
          color: black;
          font-size: 18px;
        }
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .useful-links {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;

          li {
            display: flex;
            cursor: pointer;
            padding-left: 25%;
            align-items: center;
            width: 100%;
            // height: 100%;
            text-align: left;
          }
        }
        .social-links {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;

          li {
            display: flex;
            padding-left: 25%;
            cursor: pointer;
            align-items: center;
            width: 100%;
            // height: 100%;
            text-align: left;
          }
        }
      }

      .video-div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding-bottom: 25px;
        .video {
          display: flex;
          position: relative;
          height: 100%;
          width: 85%;
          border-radius: 15px;
          overflow: hidden;
        }
        .play-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          z-index: 4;
          background-color: #f5f5f5;
          position: absolute;
          margin: 10px;
          cursor: pointer;
          transition: all 0.4s ease-in-out;
          &:hover {
            background-color: #fa5c40;
            svg {
              fill: #f5f5f7;
              stroke: #f5f5f7;
            }
          }
        }
      }
    }
    .div-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      * {
        font-weight: 600;
        font-size: 35px;
      }
      li {
        display: flex;
        cursor: pointer;
        justify-content: left;
        align-items: center;
        width: 100%;
        // height: 100%;
        padding-left: 55%;
        text-align: left;
      }
    }
    .div-3 {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;
      .cardButton {
        display: flex;
        width: 300px;
        height: 400px;
      }
    }
  }
`;
