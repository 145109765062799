import React, { useRef, useEffect } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ screen, prog, url, play }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    let offset = prog;
    playerRef.current.seekTo(offset, "fraction");
  }, [prog]);

  return (
    <div>
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={play}
        muted
        loop
        width="auto"
        height="auto"
        style={{
          aspectRatio: 16 / 9,
          minWidth: "100%",
          minHeight: "100%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          objectFit: "contain",
        }}
      />
    </div>
  );
};

export default VideoPlayer;
