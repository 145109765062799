import React from "react";

import SimpleButton from "../Button/SimpleButton";
import logos from "../../assets/logos.png";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import SectionDescription from "../../pages/Home/HomeComponents/section2-description";
export default function Partners({ screen }) {
  let navigate = useNavigate();
  return (
    <Wrapper>
      <SectionDescription
        fontWeight="600"
        fontSize="35px"
        height={"20vh"}
        text="Shipping Partners"
        screen={screen}
      />
      <div className="image">
        <img src={logos} alt="partners logos" />
      </div>
      <div className="text">
        <SectionDescription
          fontWeight="600"
          fontSize="25px"
          height={"20vh"}
          text="Millions of USA branded products are just a click away from your home."
          screen={screen}
        />
      </div>
      <div className="button" onClick={() => navigate("/register")}>
        <SimpleButton text="Get Started" />
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  * {
    display: flex;

    justify-content: center;
    align-items: center;
  }
  .image {
    width: 100%;
    img {
      width: 90%;
      height: auto;
    }
  }

  .text {
    width: 100%;
    span {
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      padding: 35px;
      @media (max-width: 800px) {
        padding: 15px;
      }
    }
  }
  .button {
    height: 120px;
  }
`;
