import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";
import cardBG from "../../assets/cardBG.png";
import { useNavigate } from "react-router-dom";
import AnimatedCardButton from "../Card/AnimatedCardButton";
export default function MenuMobile({ menuOpen, setMenuOpen }) {
  let navigate = useNavigate();

  function navNclose(route) {
    navigate(`/${route}`);
    setMenuOpen(false);
  }
  return (
    <>
      <AnimatePresence>
        {menuOpen && (
          <Wrapper
            variants={{
              hidden: {
                opacity: 0,
              },
              visible: {
                opacity: 1,
              },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{
              duration: 0.5,
            }}
            // onClick={() => setMenuOpen(false)}
          >
            <motion.div
              variants={{
                hidden: {
                  opacity: 0,
                  height: "0vh",
                },
                visible: {
                  opacity: 1,
                  height: "65%",
                },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{
                duration: 0.5,
              }}
              className="nav-div"
            >
              <div className="links-div">
                <li onClick={() => navNclose("")}>Home</li>
                <li onClick={() => navNclose("company")}>Company</li>
                <li onClick={() => navNclose("journal")}>Journal</li>
                <li onClick={() => navNclose("plans")}>Plans</li>
                <li onClick={() => navNclose("solutions")}>Solutions</li>
                <li onClick={() => navNclose("faqs")}>FAQS</li>
              </div>
              <div className="signUp-div">
                <AnimatedCardButton
                  bgPic={cardBG}
                  onClick={() => navNclose("register")}
                  BtnWidth={"100px"}
                  BtnHeight={"50px"}
                  textSize={"20px"}
                  BorderRadius={["", "", "", ""]}
                />
              </div>
            </motion.div>
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: end;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(119, 119, 119, 0.3);
  z-index: 5;

  .nav-div {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: row;
    width: 100%;
    height: 65%;
    gap: 5px;
    padding-top: 30px;
    background-color: #f5f5f7;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    * {
      font-weight: 600;
      font-size: 35px;
    }
    .signUp-div {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-right: 10px;
      width: 100%;
      height: 65%;
      border-top-right-radius: 15px;
    }

    .links-div {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;

      border-top-left-radius: 15px;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        // height: 100%;
        //   padding-left: 50%;
        text-align: left;

        span {
          border: 1px solid black;
          border-radius: 10px;
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fa5c40;
        }
      }
    }
  }
`;
