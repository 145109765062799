import React from "react";
import styled from "styled-components";

export default function ShowArticlebtn({ screen, onClick }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button onClick={onClick} screen={screen}></Button>
    </div>
  );
}

const Button = styled.div`
  width: ${({ screen }) => (screen.width < 800 ? "80px" : "100px")};
  height: ${({ screen }) => (screen.width < 800 ? "40px" : "40px")};
  background-color: transparent;

  border-radius: 15px;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(119, 119, 119, 0.4);
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:after {
    content: "All Articles";
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    font-size: ${({ screen }) => (screen.width < 800 ? "13px" : "15px")};

    background-color: transparent;
    font-weight: 400;
    border-radius: 10px;
    color: #1d1d1f;

    transition: all 0.4s ease-in-out;
  }
  &:before {
    content: "All Articles";
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: ${({ screen }) => (screen.width < 800 ? "13px" : "15px")};
    top: 50px;
    left: 0;
    font-weight: 400;
    background-color: #fa5c40;
    color: #f5f5f7;
    border-radius: 6px;
    transition: all 0.4s ease-in-out;
  }
  &:hover:before {
    transform: translateY(-50px);
  }
  &:hover:after {
    transform: translateY(-50px);
  }
`;
