import React from "react";
// import ReactPlayer from "react-player";

const SmallVideoPlayer = ({ url }) => {
 
  return (
    <div
      style={{
        aspectRatio: 16 / 9,
        minWidth: "100%",
        minHeight: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        objectFit: "contain",
        zIndex: 2,
      }}
      dangerouslySetInnerHTML={{
        __html: `
  <video
  loop="true"
    muted
    autoplay
    playsinline="true"
   
    preload="auto"
    
  >
  <source src="${url}" type="video/mp4" />
  </video>
  
`,
      }}
    >
      {/* <ReactPlayer
        url={url}
        playing={true}
        muted={muted ? true : false}
        loop
        playsInline
        width="auto"
        height="auto"
        style={{
          aspectRatio: 16 / 9,
          minWidth: "100%",
          minHeight: "100%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          objectFit: "contain",
          zIndex: 2,
        }}
      /> */}
    </div>
  );
};

export default SmallVideoPlayer;
