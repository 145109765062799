import React from "react";
import { styled } from "styled-components";

export default function ArticleCard({ screen, heading, date, titleImage }) {
  return (
    <Wrapper>
      <div className="image-div">
        <img src={titleImage} alt="tesla semi" />
      </div>
      <div className="date-title-div">
        <div className="date-div">{date}</div>
        <div className="title-div">{heading}</div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 100%;
  flex-shrink: 0;

  background: transparent;
  border-radius: 15px;

  .image-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      aspect-ratio: 16/9;
    }
  }
  .date-title-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90%;

    .date-div {
      font-family: "Suisse", Menlo, Consolas, Monaco, Liberation Mono,
        Lucida Console, monospace;
      font-weight: 400;
      display: flex;
      justify-content: start;

      align-items: center;
      width: 100%;
      height: 20%;
      padding-top: 10px;
    }
    .title-div {
      display: flex;
      justify-content: start;
      text-align: start;
      align-items: start;
      width: 100%;
      height: 100%;
      padding-top: 10px;
      font-weight: 500;
      line-height: 1.8;
      font-size: 17px;
      cursor: pointer;
      &:hover {
        color: #fa5c40;
      }
    }
  }
`;
