import React, { useState } from "react";
import { styled } from "styled-components";
import Accordion from "./accordion";
import SEO from "../../components/HeadSEOComponent/HeadSEO";
const faq = [
  {
    question: "What does DelCargo do?",
    answer:
      "DelCargo provides international shipping services, helping you ship products from various countries to your desired destination. We offer reliable and efficient shipping solutions to ensure your packages reach you safely and on time.",
  },
  {
    question: "How do I sign up to become a DelCargo customer?",
    answer:
      'Becoming a DelCargo customer is easy. Simply visit our website and click on the "Sign Up" button. Fill out the required information, create your account, and you\'re ready to start using our services.',
  },
  {
    question: "Which countries does DelCargo ship to?",
    answer:
      "DelCargo offers shipping services to numerous countries worldwide. We have a wide network that allows us to deliver packages to most destinations around the globe. However, for specific information about shipping to a particular country, please contact our customer support team.",
  },
  {
    question:
      "What can I do if a store requires a local debit card and shipping address?",
    answer:
      "If a store requires a local debit card or shipping address, DelCargo can assist you through our Assisted Purchase service. Simply provide us with the details of the product you want to purchase, and we'll make the purchase on your behalf using our payment methods and arrange for delivery to our warehouse. From there, we will ship the item to your international address.",
  },
  {
    question: "What is a Direct Purchase with DelCargo?",
    answer:
      "With our Direct Purchase service, you can shop directly from online stores and have the items delivered to our warehouse. Once received, we will forward the packages to your specified international address, ensuring a smooth and hassle-free delivery process.",
  },
  {
    question: "What is an Assisted Purchase with DelCargo?",
    answer:
      "DelCargo offers an Assisted Purchase service for situations where online stores require a local payment method or shipping address. By using our Assisted Purchase service, you can provide the details of the desired product, and we will make the purchase on your behalf using our resources. The item will be shipped to our warehouse, and then we will forward it to your international address.",
  },
  {
    question: "How can I submit an Assisted Purchase request with DelCargo?",
    answer:
      "To submit an Assisted Purchase request, log into your DelCargo account and navigate to the Assisted Purchase section. Fill out the required form with the details of the product you want to purchase, including the online store, item description, and any specific instructions. Our team will handle the rest of the process for you.",
  },
  {
    question:
      "Can I shop from international online stores using DelCargo's services?",
    answer:
      "Yes, you can shop from international online stores using DelCargo's services. We provide shipping solutions for packages originating from various countries, allowing you to enjoy global online shopping convenience.",
  },
  {
    question: "How many items can I purchase at a time with DelCargo?",
    answer:
      "There is no strict limit to the number of items you can purchase at a time with DelCargo. However, please ensure that the items comply with our shipping guidelines and any applicable restrictions or regulations.",
  },
  {
    question: "Does DelCargo offer package consolidation?",
    answer:
      "Yes, DelCargo offers package consolidation services. If you have multiple small packages, we can consolidate them into a larger box to optimize shipping costs and reduce the number of separate shipments.",
  },
  {
    question: "Can DelCargo assist with repackaging items into smaller boxes?",
    answer:
      "Yes, DelCargo provides repackaging services to ensure your items are packaged in the most efficient and secure manner. We can repack your items into smaller boxes, helping to minimize shipping costs while maintaining the safety of your shipment.",
  },
  {
    question: "How do I submit a consolidation request with DelCargo?",
    answer:
      "To submit a consolidation request, log into your DelCargo account and access the Consolidation section. Follow the provided instructions to indicate which packages you want to consolidate, provide any necessary details, and select your shipping address. Once submitted, our team will handle the consolidation process for you.",
  },
  {
    question:
      "I have submitted a consolidation request with DelCargo. When will my shipment be ready?",
    answer:
      "After submitting a consolidation request with DelCargo, it typically takes 12 to 24 business hours for your shipment to be prepared. Once your shipment is ready, we will notify you, and it will be promptly scheduled for delivery to your specified address.",
  },
  {
    question:
      "Are there any restrictions on items I can purchase with DelCargo?",
    answer:
      'Yes, there are certain items that may be restricted due to export regulations and carrier restrictions. Please refer to our "Restricted Items" list before making a purchase to ensure compliance with the guidelines.',
  },
  {
    question: "Does DelCargo provide insurance for damaged or lost items?",
    answer:
      "DelCargo offers insurance options through our partnered shipping carriers. Depending on the carrier you choose, insurance coverage may be available for your shipments. In case of any damage or loss, please contact us within the specified timeframe to initiate the claims process.",
  },
  {
    question: "How can I send a picture via Messages with DelCargo?",
    answer:
      "When using our messaging feature, you can send a picture by clicking on the attachment icon within the chat window. This will allow you to select and upload the desired image.",
  },
  {
    question:
      "How does DelCargo assist me in shopping from international stores?",
    answer:
      "DelCargo provides you with a convenient way to shop from international stores by offering a reliable shipping service. We give you a local address to use during your purchase, and we take care of forwarding your packages to your international destination, ensuring a seamless shopping experience.",
  },
  {
    question: "Does DelCargo accept packages on weekends?",
    answer:
      "DelCargo accepts packages during weekdays, as our warehouse operates on business days. If your estimated delivery falls on a weekend, we recommend contacting your courier to reschedule the delivery for a weekday.",
  },
  {
    question: "Can DelCargo reach out to customers via SMS campaigns?",
    answer:
      "DelCargo may occasionally reach out to customers via SMS campaigns for efficient communication. We may use the provided contact numbers to provide important updates, promotional offers, or other relevant information.",
  },
];

export default function FAQS({ screen }) {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordionIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };
  return (
    <>
      <SEO
        title="FAQs | DelCargo"
        description="Explore Answers to your Questions"
      />
      <Wrapper>
        <div className="heading-div">
          <h1>FAQS</h1>
          <h2>Explore Answers to your Questions</h2>
        </div>
        <div className="content">
          {faq.map((data, i) => (
            <Accordion
              title={data.question}
              content={data.answer}
              activeIndex={activeAccordionIndex}
              index={i}
              onAccordionClick={handleAccordionClick}
            />
          ))}
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 70px 50px 50px 50px;
  @media (max-width: 800px) {
    padding: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100vw;

    height: auto;
    padding: 50px 170px 50px 170px;
    @media (max-width: 800px) {
      padding: 20px 10px 10px 10px;
    }
  }
  .heading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 50vh;

    padding: 50px;
    @media (max-width: 800px) {
      padding: 10px;
      height: 350px;
    }
    h2 {
      font-size: 30px;
      font-weight: 400;
      color: #1d1d1f;
    }
    h1 {
      font-size: 60px;
      font-weight: 600;
      color: #1d1d1f;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 50px;
        font-weight: 500;
      }
    }
  }
`;
