import { useEffect, useState } from "react";
import "./App.css";
import NavBar from "./components/NavBar/navbar";
import Home from "./pages/Home/Home";
import Footer from "./components/footer/footer";
import MenuDesktop from "./components/NavBar/MenuDesktop";
import MenuMobile from "./components/NavBar/MenuMobile";
import { Routes, Route, useLocation } from "react-router-dom";
import NoMatch from "./pages/NoMatch/NoMatch";
import LoginPage from "./pages/Login/login";
import { Controller, Scene } from "react-scrollmagic";

import CustomVideoPlayer from "./components/VideoPlayer/CustomVideoPlayer";
import SignUp from "./pages/SignUp/signup";
import PaymentAndTax from "./pages/InfoPages/Payment&Tax";
import TermsOfService from "./pages/InfoPages/TermsOfService";
import ReturnPolicy from "./pages/InfoPages/ReturnPolicy";
import PrivacyPolicy from "./pages/InfoPages/PrivacyPolicy";
import Journal from "./pages/Journal/journal";
import StartingAnimation from "./components/StartAnimation/StartingAnimation";
import FloatingButton from "./components/Button/FloatingButton";
import Plans from "./pages/Plans/plans";
import FAQS from "./pages/FAQS/faqs";
import Article from "./pages/Journal/article";
import Aboutus from "./pages/Company/about";
import BusinessSolutions from "./pages/InfoPages/businessSolutions";

function App() {
  const [startingAnimationComplete, setstartingAnimationComplete] =
    useState(false);

  const [videoURL, setVideoURL] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [hideLoginBtn, setHideLoginBtn] = useState(false);
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <>
      <Controller>
        <Scene indicators={false} duration={100} triggerHook={0}>
          {(progress) => (
            <div className="App">
              <Routes>
                <Route
                  path="/"
                  exact
                  element={
                    <Home
                      startingAnimationComplete={startingAnimationComplete}
                      screen={screenSize}
                      setVideoURL={setVideoURL}
                    />
                  }
                />
                <Route
                  path="/company"
                  element={<Aboutus screen={screenSize} />}
                />
                <Route
                  path="/solutions"
                  element={<BusinessSolutions screen={screenSize} />}
                />
                <Route
                  path="/journal"
                  element={<Journal screen={screenSize} />}
                />

                <Route
                  path="/journal/:id"
                  element={<Article screen={screenSize} />}
                />

                <Route path="/faqs" element={<FAQS screen={screenSize} />} />

                <Route path="/plans" element={<Plans screen={screenSize} />} />
                <Route
                  path="/login"
                  element={
                    <LoginPage
                      screen={screenSize}
                      startingAnimationComplete={startingAnimationComplete}
                    />
                  }
                />
                <Route
                  path="/payment-and-tax"
                  element={<PaymentAndTax screen={screenSize} />}
                />
                <Route
                  path="/privacy-policy"
                  element={<PrivacyPolicy screen={screenSize} />}
                />

                <Route
                  path="/terms-of-service"
                  element={<TermsOfService screen={screenSize} />}
                />
                <Route
                  path="/return-policy"
                  element={<ReturnPolicy screen={screenSize} />}
                />
                <Route
                  path="/register"
                  element={
                    <SignUp
                      screen={screenSize}
                      startingAnimationComplete={startingAnimationComplete}
                    />
                  }
                />

                <Route path="*" element={<NoMatch />} />
              </Routes>
              <StartingAnimation
                complete={startingAnimationComplete}
                setComplete={setstartingAnimationComplete}
              />
              <FloatingButton />
              <NavBar
                screen={screenSize}
                setMenuOpen={setMenuOpen}
                menuOpen={menuOpen}
                hideLoginBtn={hideLoginBtn}
                setHideLoginBtn={setHideLoginBtn}
                progress={progress}
              />
              <Footer screen={screenSize} />
              {screenSize.width >= 800 ? (
                <MenuDesktop
                  screen={screenSize}
                  menuOpen={menuOpen}
                  setMenuOpen={setMenuOpen}
                  setVideoURL={setVideoURL}
                />
              ) : (
                <MenuMobile menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
              )}

              <CustomVideoPlayer
                setVideoURL={setVideoURL}
                url={videoURL}
                screen={screenSize}
              />
            </div>
          )}
        </Scene>
      </Controller>
    </>
  );
}

export default App;
