import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation, useScroll } from "framer-motion";
import { useLocation } from "react-router-dom";
export default function LoginButton({
  textSize,
  onClick,
  screen,
  textWhite,
  menuOpen,
}) {
  let location = useLocation();
  const [darkbtnBacnground, setdarkBtnBackground] = useState(false);
  useEffect(() => {
    if (
      location.pathname === "/payment-and-tax" ||
      location.pathname === "/return-policy" ||
      location.pathname === "/terms-of-service" ||
      location.pathname === "/privacy-policy" ||
      location.pathname === "/register" ||
      location.pathname === "/plans" ||
      location.pathname === "/journal" ||
      location.pathname === "/faqs" ||
      location.pathname.includes("journal") ||
      location.pathname === "/company" ||
      location.pathname === "/solutions"
    ) {
      setdarkBtnBackground(true);
    } else {
      setdarkBtnBackground(false);
    }
  }, [location]);

  const { scrollYProgress, scrollY } = useScroll();
  let [isAnimated, setIsAnimated] = useState(false);
  const animationControls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollY.current >= 100) {
        animationControls.start("visible");
        setIsAnimated(true);
      } else {
        animationControls.start("hidden");
        setIsAnimated(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollYProgress, animationControls, scrollY]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        onClick={onClick}
        style={{
          fontSize: textSize,
          backgroundColor: `${
            menuOpen || darkbtnBacnground ? "#fa5c40" : "transparent"
          }`,
        }}
        menuOpen={menuOpen}
        darkbtnBacnground={darkbtnBacnground}
        isAnimated={isAnimated}
        textWhite={textWhite}
        variants={{
          hidden: {
            border: `${screen.width < 800 ? "none" : "2px solid #f5f5f7"} `,
          },
          visible: {
            border: `${screen.width < 800 ? "none" : "2px solid #1d1d1f"} `,
          },
        }}
        initial="hidden"
        animate={animationControls}
        transition={{ duration: 0.2 }}
      ></Button>
    </div>
  );
}

const Button = styled(motion.div)`
  width: 150px;
  height: 50px;
  @media (max-width: 800px) {
    width: 70px;
  }
  background-color: transparent;
  border: 2px solid #f5f5f7;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "LogIn";
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    font-weight: bold;
    border-radius: 10px;
    @media (max-width: 800px) {
      background-color: transparent;
      color: #1d1d1f;
    }
    color: ${({ isAnimated, textWhite }) =>
      isAnimated && !textWhite ? "#1d1d1f" : "#f5f5f7"};
    transition: all 0.4s ease-in-out;
  }
  &:before {
    content: "LogIn";
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 0;
    font-weight: bold;
    background-color: #fa5c40;
    @media (max-width: 800px) {
      display: none;
      background-color: transparent;
    }
    color: #f5f5f7;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
  }
  &:hover:before {
    transform: ${({ menuOpen, darkbtnBacnground }) =>
      menuOpen || darkbtnBacnground ? "translateY(0px)" : "translateY(-50px)"};
    @media (max-width: 800px) {
      transform: translateY(0px);
    }
  }
  &:hover:after {
    transform: ${({ menuOpen, darkbtnBacnground }) =>
      menuOpen || darkbtnBacnground ? "translateY(0px)" : "translateY(-50px)"};
    @media (max-width: 800px) {
      transform: translateY(0px);
    }
  }
`;
