import React from "react";
import { styled } from "styled-components";

import { useNavigate } from "react-router-dom";
export default function AboutPageCard({
  screen,
  desc1,
  desc2,
  cardimage,
  btnHeading,
  btnLink,
}) {
  let navigate = useNavigate();
  return (
    <Wrapper cardimage={cardimage}>
      <div className="div-wrapper">
        <div className="div-1">
          <div className="div-1-1">
            <h1>DelCargo</h1>
          </div>
          <div className="div-1-2">
            <p>
              {desc1[0]}
              <br /> {desc1[1]}
            </p>
          </div>
          <div className="div-1-3">
            <p>
              <br /> {desc2}
              <br />
              <br />
            </p>
          </div>
          <div className="div-1-4">
            <span onClick={() => navigate(`/${btnLink}`)}>
              <AButton text={btnHeading} />
            </span>
          </div>
        </div>
        <div className="div-2">
          <div className="div-2-image"></div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 95vh;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
    height: auto;
    min-height: 100vh;
  }
  border-radius: 15px;

  * {
    border-radius: 15px;
  }
  .div-wrapper {
    display: flex;
    flex-direction: row;
    background-color: white;
    @media (max-width: 800px) {
      flex-direction: column-reverse;
    }
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 25px;
    @media (max-width: 800px) {
      padding: 10px;
    }

    ////
    .div-1 {
      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 15px;
      @media (max-width: 800px) {
        padding: 10px;
      }
      .div-1-1 {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        height: 100%;
        h1 {
          font-weight: 400;
          font-size: 20px;
        }
      }
      .div-1-2 {
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
        width: 100%;
        height: 100%;
        p {
          font-weight: 500;
          font-size: 30px;
          @media (max-width: 800px) {
            font-size: 20px;
          }
        }
      }
      .div-1-3 {
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
        width: 100%;
        height: 100%;
        p {
          font-weight: 400;
          font-size: 17px;
        }
      }

      .div-1-4 {
        display: flex;
        justify-content: left;
        align-items: center;

        width: 100%;
        height: 100%;
        @media (max-width: 800px) {
          padding: 20px;
        }
      }
    }
    ///
    ////
    .div-2 {
      display: flex;

      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .div-2-image {
        display: flex;

        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        @media (max-width: 800px) {
          height: 220px;
        }
        padding: 15px;
        background-image: ${({ cardimage }) => `url(${cardimage})`};
        background-size: cover;
        // background-attachment: fixed;
        background-position: center;

        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    ////
  }
`;

function AButton({ text }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button text={text}></Button>
    </div>
  );
}

const Button = styled.div`
  width: 150px;
  height: 50px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  border: 1px solid #1d1d1f;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: ${(props) => `"${props.text}"`};
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    font-weight: 400;
    border-radius: 10px;
    color: #1d1d1f;

    transition: all 0.4s ease-in-out;
  }
  &:before {
    content: ${(props) => `"${props.text}"`};
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 0;
    font-weight: 400;
    background-color: #1d1d1f;
    color: #f5f5f7;
    border-radius: 6px;
    transition: all 0.4s ease-in-out;
  }
  &:hover:before {
    transform: translateY(-50px);
  }
  &:hover:after {
    transform: translateY(-50px);
  }
`;
