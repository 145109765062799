import { motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";
import SectionOneHome from "./HomeComponents/section1-home";
import SectionDescription from "./HomeComponents/section2-description";
import SectionVideo from "./HomeComponents/section3-Video";
import SectionBenefitsTransition from "./HomeComponents/section4-Benefits&Transition";
import Logo from "../../components/Logo/logo";
import SectionCards from "./HomeComponents/section5-Cards";
import SectionAbout from "./HomeComponents/section6-Aboutus";
import SectionJournal from "./HomeComponents/section7-journal";
import SEO from "../../components/HeadSEOComponent/HeadSEO";
import SectionUpComingEvents from "./HomeComponents/section8-upcomingEvents";

export default function Home({
  screen,
  setVideoURL,
  startingAnimationComplete,
}) {
  return (
    <>
      <SEO
        title="Home | DelCargo"
        description="DelCargo: Efficient Cargo Solutions for Seamless Logistics"
      />
      <Wrapper>
        <SectionOneHome
          screen={screen}
          startingAnimationComplete={startingAnimationComplete}
          setVideoURL={setVideoURL}
        />
        <SectionDescription
          fontWeight="400"
          fontSize="30px"
          height={"50vh"}
          text="GlobalConnect: Effortless, Swift, Tax-Free shipping worldwide. 60-day storage & consolidated shipping. Just a click away!"
          screen={screen}
        />

        <SectionVideo screen={screen} />
        <SectionDescription
          fontWeight="600"
          fontSize="40px"
          height={"40vh"}
          text="Seamless Solutions for Your Cargo Needs: Choose Our Professional Cargo Service"
          screen={screen}
        />
        <div
          style={{
            height: "55vh",
          }}
        ></div>
        <SectionBenefitsTransition screen={screen} />

        <div
          style={{
            height: "55vh",
          }}
        ></div>

        <SectionCards screen={screen} />
        <SectionAbout screen={screen} />
        <SectionJournal screen={screen} />
        <SectionUpComingEvents screen={screen} />
        <Logo startAnimation={false} screen={screen} delay={0} />
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.main)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
