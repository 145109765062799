import { motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";
import SEO from "../../components/HeadSEOComponent/HeadSEO";

export default function TermsOfService({ screen }) {
  return (
    <>
      <SEO title="Terms of Services | DelCargo" description="" />
      <Wrapper>
        <div className="heading-div">
          <h1>Terms Of Service</h1>
          <h2>DELCARGO</h2>
        </div>
        <div className="content-div">
          <h2>Introduction</h2>
          <p>
            Please read these Terms and Conditions ("Terms", "Terms and
            Conditions") carefully before using the delcargo.com website
            (referred to as the "Service" hereafter) operated by DelCargo
            (referred to as "us," "we," or "our" hereafter). Your access to and
            use of the Service is subject to your acceptance and compliance with
            these Terms. These Terms apply to all visitors, users, and others
            who access or use the Service. By accessing or using the Service,
            you agree to be bound by these Terms.
          </p>
          <p>
            <b>Warning:</b> If you disagree with any part of the terms, then you
            may not access/use the Service.
          </p>
          <h3>Transactions</h3>
          <p>
            <b>Transaction Limit:</b> Any transaction in excess of the $500
            limit must be done via Bank to Bank Wire transfer, BitCoin, or
            Transferwise. Credit Card will not be accepted for transactions
            above $500, no exceptions.
          </p>
          <p>
            <b>Assisted Purchase:</b> Assisted Purchase requests will not be
            placed, and packages will not be shipped until your payment clears.
          </p>
          <p>
            <b>Prepaid Cards:</b> We do not accept prepaid cards; only Credit
            Cards will be accepted. We also do not facilitate transactions for
            purchasing prepaid cards.
          </p>
          <p>
            <b>
              DelCargo reserves the right to refuse and restrict acceptable
              payment methods to any customer at any time.
            </b>
          </p>
          <p>
            <b>
              We DO NOT conduct any type of transactions with the following
              countries:
            </b>{" "}
            Cuba, Iran, Myanmar, North Korea, Sudan, and Syria.
          </p>
          <h3>Missing, Lost, or Damaged Packages</h3>
          <p>
            <b>Charges:</b> In the case of lost or damaged packages, please
            contact us to file a claim. False claims will be charged a minimum
            of $5 plus any investigation expenses incurred.
          </p>
          <p>
            <b>Claim Duration:</b> All claims for lost or damaged packages must
            be reported to DelCargo within 7 days; otherwise, they will be
            automatically denied.
          </p>
          <p>
            <b>Insurance:</b> If you do not insure your shipments, you cannot
            recover money if they are lost or damaged during transit!
          </p>
          <p>
            When you purchase insurance, it is obtained through EasyPost.
            Insurance for packages lost during transit is only covered for the
            value entered in the customs declaration.
          </p>
          <p>
            You have to be able to prove the value entered by invoice or order.
            If you claim a lower value in the customs declaration, that is what
            you get paid! You have to support insurance claims for lost or
            damaged items with invoices of the items.
          </p>
          <h3>Cancellation</h3>
          <p>
            DelCargo is not able to refuse any selected package due to the
            volume of packages we receive each day. However, you can opt for
            shipment cancellation before the package arrives. In case you fail
            to do so, shipment charges would be applied.
          </p>
          <h3>Unclaimed and Abandoned Packages</h3>
          <p>
            If we receive a package that does not match any account holder's
            name, we will hold it for 30 days and wait for it to be claimed. If
            the package remains unclaimed for 30 days, we will deem the package
            to be Unclaimed and Abandoned.
          </p>
          <p>
            DelCargo will sell abandoned packages at public auction or private
            sale without further notice to you.
          </p>
          <h3>Security Hold</h3>
          <p>
            Occasionally, discrepancies or other problems with account status
            and payments arise that cause us to put a Security Hold on the
            account.
          </p>
          <p>
            While on Security Hold, we will not incur any expenses on the
            account, including shipping expenses. We will keep you informed of
            the status of the Security Hold and let you know how you can help us
            to clear it up. If your account is on Security Hold, we might
            request documents or information from you. If you have been
            requested any documents and we do not hear back within 7 days, we
            will deem your packages to be Abandoned (Please review the
            'Unclaimed and Abandoned Packages' section).
          </p>
          <h3>Package Refusal</h3>
          <p>
            DelCargo is not able to refuse any selected package due to the
            volume of packages we receive each day.
          </p>
          <h3>Shipping and Export</h3>
          <p>
            DelCargo ships only by the shipping methods offered in the shipping
            calculator shown on DelCargo. DelCargo is not responsible for the
            actual shipping service that is chosen by you. All terms and
            conditions of the selected shipping service (Aramex, DHL, FedEx,
            UPS, USPS, etc.) apply to you.
          </p>
          <p>
            You are responsible for assuring that the shipping of your package
            is not restricted by its contents, size, or volume. If your shipment
            is confiscated by customs due to contents, we are not responsible.
          </p>
          <p>
            You authorize DelCargo to open packages if there is suspicion over
            the content of the package (illegal goods) or any practical reason.
            Any problem with the received package must be reported within 10
            days of delivery. Airmail Economy does not provide proof of mailing,
            insurance, or tracking features.
          </p>
          <p>
            <b>
              As stated under Transactions, We do not conduct any type of
              transactions with the following countries:
            </b>{" "}
            Cuba, Iran, Myanmar, North Korea, Sudan, and Syria or any
            destination for which the United States requires an export license
            without first obtaining such license(s).
          </p>
          <p>
            <b>
              Diversion of these commodities contrary to the United States law
              is prohibited. Any and all export license requirements are the
              sole responsibility of the individual and/or entity in contractual
              agreement with DelCargo.
            </b>
          </p>
          <h3>Storage Allowance</h3>
          <p>
            <b>Inbound Shipments:</b> DelCargo will store your Inbound packages
            in our warehouse for up to 180 days without charge. If you exceed
            180 days inbound storage allowance, you will be charged $1 per kg
            per day for up to 15 additional days. If we do not receive a request
            to ship within the 15-day inbound storage extension, we will deem
            the package to be Unclaimed and Abandoned (please review Unclaimed
            and Abandoned section).
          </p>
          <p>
            <b>Outbound Shipments:</b> DelCargo will store your Outbound
            packages in our warehouse for up to 15 days without charge. If you
            exceed 15 days' outbound storage allowance, you will be charged $1
            per kg per day for up to 15 additional days. If we do not receive a
            request to ship within the additional 15-days outbound storage
            extension, we will deem the package to be Unclaimed and Abandoned
            (please review Unclaimed and Abandoned section).
          </p>
          <p>
            <b>Canceled Shipments:</b> Packages released from a shipment due to
            cancellation of said shipment are required to be shipped out within
            the 15-days outbound allowance period (they will NOT resume their
            180-day inbound storage allowance).
          </p>
          <h3>DelCargo Liability</h3>
          <p>
            DelCargo's responsibility for shipped packages shall not exceed the
            number of handling fees (excluding actual shipping fees).
          </p>
          <p>
            Customer indemnifies and holds DelCargo harmless from any and all
            claims, including legal fees incurred in defending against said
            claims, from third parties arising out of the transmission of the
            package/mail sent by the customer through DelCargo.
          </p>
          <p>
            DelCargo will not be responsible for any damage from an error or
            omission caused by you providing false or incorrect information or
            for failing to provide all necessary information.
          </p>
          <h3>Content</h3>
          <p>
            DelCargo has the right to all content sent to our email address/es
            or uploaded to our website. By using our services, you give us
            permission to use any and all of this content for commercial and
            advertising purposes without any compensation to you. DelCargo has
            full ownership rights to any pictures taken by us of any packages,
            items, or invoices routed through any of our warehouses in any way
            we please.
          </p>
          <h3>Links To Other Websites</h3>
          <p>
            Our Service may contain links to third-party websites or services
            that are not owned or controlled by DelCargo. DelCargo has no
            control over and assumes no responsibility for the content, privacy
            policies, or practices of any third-party websites or services.
          </p>
          <p>
            You further acknowledge and agree that DelCargo shall not be
            responsible or liable, directly or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with the use
            of or reliance on any such content, goods, or services available on
            or through any such web sites or services.
          </p>
          <p>
            We strongly advise you to read the terms and conditions and privacy
            policies of any third-party websites or services that you visit.
          </p>
          <h3>Termination</h3>
          <p>
            We may terminate or suspend access to our Service immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if you breach the Terms.
          </p>
          <p>
            All provisions of the Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity,
            and limitations of liability.
          </p>
          <p>
            Upon termination, your right to use the Service will immediately
            cease. If you wish to terminate your account, you may simply
            discontinue using the Service.
          </p>
          <h3>Governing Law</h3>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of Delaware, without regard to its conflict of law provisions.
          </p>
          <p>
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service and supersede and replace any prior agreements we might have
            between us regarding the Service.
          </p>
          <h3>Changes</h3>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material, we will try to
            provide at least 30 days' notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </p>
          <p>
            By continuing to access or use our Service after those revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, please stop using the Service.
          </p>
          <h2>Customer Responsibilities</h2>

          <h3>Accounts</h3>
          <p>
            When you create an account with us, you must provide us with
            information that is accurate, complete, and current at all times.
            Failure to do so constitutes a breach of the Terms, which may result
            in immediate termination of your account on our Service.
          </p>
          <p>
            You are responsible for safeguarding the password that you use to
            access the Service and for any activities or actions under your
            password, whether your password is with our Service or a third-party
            service.
          </p>
          <p>
            You agree not to disclose your password to any third party. You must
            notify us immediately upon becoming aware of any breach of security
            or unauthorized use of your account.
          </p>
          <h3>Import and Export Documentation</h3>
          <p>
            DelCargo does not guarantee the delivery of packages that do not
            comply with export regulations of the originating country or the
            import regulations of the destination country.
          </p>
          <p>
            The customer is responsible for determining and adhering to the
            regulations for their shipments, including all customs requirements
            of the destination country.
          </p>
          <p>
            You are responsible for the accuracy of all customs declarations and
            documentation and for all customs duties in the destination country.
            Any such duties or charges are in addition to the fees earned by
            DelCargo for its package/mail forwarding service.
          </p>
          <p>
            You acknowledge that you have a duty to and are solely liable for
            providing all information required by the laws of the country that
            is the final destination of your package and the laws and
            regulations of the originating country, including, without
            limitation, information required by relevant export regulations and
            import laws.
          </p>
          <p>
            DelCargo assumes no responsibility to act as a record-keeper or
            record-keeping agent for you or the exporting party. You are
            responsible for supplying DelCargo with all information regarding
            the exporting party, including its name and identification number,
            and any other information required for export transactions as
            described by the applicable laws and regulations.
          </p>
          <h3>Other Rules</h3>
          <p>
            You agree to comply with all laws and regulations applicable to your
            access and use of DelCargo. You may not harvest personal data
            (including email addresses) from DelCargo, and specifically, you may
            not use email addresses displayed on our website to solicit users to
            join or contribute to your services.
          </p>
          <p>
            We have the right to investigate and prosecute violations of any of
            the above to the fullest extent of the law. We may involve and
            cooperate with law enforcement authorities in prosecuting users who
            violate the terms of this Agreement.
          </p>
          <p>
            You acknowledge that DelCargo has no obligation to monitor your
            access to or use of DelCargo, but has the right to do so for the
            purpose of operating DelCargo, to ensure your compliance with this
            Agreement, or to comply with applicable law or the order or
            requirement of a court, administrative agency, or other governmental
            body.
          </p>
          <p>
            Unless we explicitly agree otherwise in writing, you may not use any
            automated means (such as scripts) to access DelCargo or collect
            information from our website and/or database.
          </p>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
  }

  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    list-style: square;
    padding-left: 100px;
    @media (max-width: 800px) {
      padding-left: 15px;
    }
    li {
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
    }
  }

  .content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 70vw;

    @media (max-width: 800px) {
      width: 100vw;
      padding: 20px;
      * {
        text-align: left;
      }
    }
    height: auto;

    h2 {
      font-size: 40px;
      font-weight: 500;
      padding-bottom: 20px;
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (max-width: 800px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .heading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 80vh;

    padding: 50px;
    @media (max-width: 800px) {
      padding: 10px;
      height: 350px;
    }
    h2 {
      font-size: 30px;
      font-weight: 400;
      color: #1d1d1f;
    }
    h1 {
      font-size: 60px;
      font-weight: 600;
      color: #1d1d1f;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 50px;
        font-weight: 500;
      }
    }
  }
`;
