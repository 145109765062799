import React, { useState } from "react";
import { styled } from "styled-components";
import cardBG from "../../assets/cardBG.png";

import AnimatedCardButton from "../Card/AnimatedCardButton";
import { useNavigate } from "react-router-dom";

let initialValues = {
  email: "",
};
export default function Footer({ screen }) {
  const [formData, setFormData] = useState(initialValues);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setFormData(initialValues);
    ///handle Submit
  };

  function validateEmail(email) {
    // Regular expression pattern for email validation
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the pattern
    return pattern.test(email);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "email") {
      // Perform email validation
      const isValidEmail = validateEmail(value);

      // Pass the email and validation result to the parent component
      setIsValidEmail(formData.email === "" || isValidEmail);
    }
  };

  let navigate = useNavigate();

  return (
    <Wrapper>
      <div className="footer">
        <span>
          DelCargo <p>&copy;</p> {new Date().getFullYear()}
        </span>
        <div className="div-1">
          <div className="div-1-1">
            <li onClick={() => navigate("/")}>Home</li>
            <li onClick={() => navigate("/company")}>Company</li>
            <li onClick={() => navigate("/journal")}>Journal</li>
            <li onClick={() => navigate("/plans")}>Plans</li>
            <li onClick={() => navigate("/solutions")}>Solutions</li>
            <li onClick={() => navigate("/faqs")}>FAQS</li>
          </div>
          <div className="div-1-2">
            <li onClick={() => navigate("/return-policy")}>Return Policy</li>
            <li onClick={() => navigate("/payment-and-tax")}>Payment & Tax</li>
            <li onClick={() => navigate("/terms-of-service")}>
              Terms of Service
            </li>

            <li onClick={() => navigate("/privacy-policy")}>Privacy Policy</li>
          </div>
        </div>
        <div className="div-2">
          <div className="div-2-1">
            <li>Instagram</li>
            <li>Facebook</li>
            <li>LinkedIn</li>
          </div>

          {/* // */}
          <div className="div-2-2">
            <h1>News Letter</h1>
            <div className="email-div">
              <div
                style={{
                  border: `${
                    !isValidEmail && formData.email !== ""
                      ? "1px solid red"
                      : " 1px solid rgba(119, 119, 119, 0.6)"
                  }`,
                }}
                className="email-border"
              >
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  placeholder="Email Address"
                  onChange={handleChange}
                />
                <button
                  onClick={handleSubmit}
                  disabled={
                    formData.email === "" ||
                    formData.email == null ||
                    !isValidEmail
                      ? true
                      : false
                  }
                >
                  {" "}
                  Subscribe
                </button>
              </div>
            </div>
            {submitted && (
              <p>Thank you, you are now subscribed to the newsletter.</p>
            )}
          </div>

          {/* /// */}
        </div>
        <div className="div-3">
          <div className="div-3-image">
            <AnimatedCardButton
              onClick={() => navigate("/register")}
              bgPic={cardBG}
              BtnWidth={"100px"}
              BtnHeight={"50px"}
              BorderRadius={[
                `${screen.width < 800 ? "15px" : "0px"}`,
                "",
                "",
                `${screen.width < 800 ? "15px" : "0px"}`,
              ]}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100vw;
  height: 100vh;

  @media (max-width: 800px) {
    min-height: 100vh;
    height: auto;
  }
  padding: 20px;
  @media (max-width: 800px) {
    padding: 10px;
  }
  * {
    list-style: none;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    padding-left: 15px;
    @media (max-width: 800px) {
      flex-direction: column;
      height: 95%;
      margin-bottom: 110px;
      padding-left: 0px;
    }
    background-color: #f5f5f7;
    width: 95%;
    height: 75%;

    border-radius: 15px;
    span {
      display: flex;
      flex-direction: row;
      rotate: z 180deg;
      writing-mode: vertical-rl;
      font-weight: 600;

      @media (max-width: 800px) {
        writing-mode: horizontal-tb;
        rotate: z 0deg;
      }
      p {
        rotate: z 90deg;
        @media (max-width: 800px) {
          rotate: z 0deg;
        }
      }
    }
    * {
      li {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 20%;
        width: 100%;
        cursor: pointer;
        @media (max-width: 450px) {
          justify-content: center;
          align-items: center;
          padding-left: 0%;
        }
      }
    }
    .div-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding-top: 20px 5px 5px 5px;
      width: 100%;
      height: 100%;

      .div-1-1 {
        display: flex;
        justify-content: start;
        align-items: start;
        padding: 5px;
        padding-top: 20%;
        flex-direction: column;
        @media (max-width: 800px) {
          justify-content: center;
          padding-top: 0%;
        }
        width: 100%;
        height: 100%;
      }
      .div-1-2 {
        display: flex;
        justify-content: start;
        align-items: start;
        padding: 5px;
        padding-top: 20%;
        flex-direction: column;
        @media (max-width: 800px) {
          justify-content: center;
          padding-top: 0%;
        }
        width: 100%;
        height: 100%;
      }
    }
    .div-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 20px 5px 5px 5px;
      width: 100%;
      height: 100%;

      .div-2-1 {
        display: flex;
        justify-content: start;
        align-items: start;

        @media (max-width: 800px) {
          justify-content: center;
          padding-top: 0%;
        }
        flex-direction: column;
        padding: 5px;
        padding-top: 20%;
        width: 100%;
        height: 100%;
      }
      .div-2-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        flex-direction: column;
        @media (max-width: 800px) {
          height: 150px;
        }
        width: 100%;
        height: 100%;
        h1 {
          display: flex;
          justify-content: start;
          align-items: end;
          padding: 5px;
          font-weight: 500;
          @media (max-width: 800px) {
            align-items: center;
          }
          width: 100%;
          height: 100%;
        }
        .email-div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          width: 100%;
          height: 100%;
          // padding: 5px;
          .email-border {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            width: 100%;
            height: 60px;
            border: 1px solid rgba(119, 119, 119, 0.6);
            border-radius: 15px;
            input {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5px;
              width: 100%;
              height: 50px;
              background: transparent;
              outline: none;
              color: black;

              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
            }
            button {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px;
              width: 120px;
              height: 50px;
              border-radius: 10px;
              outline: none;
              background-color: black;
              color: #f5f5f7;
              font-weight: 500;
              transition: all 0.3s ease-in-out;
              &:hover {
                background-color: #fa5c40;
              }
            }
          }
        }
      }
    }
    .div-3 {
      display: flex;
      justify-content: end;
      align-items: center;

      width: 100%;
      height: 100%;
      @media (max-width: 800px) {
        width: 100%;
        height: 300px;
      }
      .div-3-image {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 65%;
        @media (max-width: 800px) {
          width: 100%;
        }
        height: 100%;
      }
    }
  }
`;
