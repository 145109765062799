import React, { useState } from "react";
import styled from "styled-components";

const FloatingLabelInput = ({
  type,
  label,
  value,
  handleChange,
  isValidEmail,
  inputName,
  required,
  validation,
  email,
}) => {
  const [isFloating, setIsFloating] = useState(false);

  const handleFocus = () => {
    setIsFloating(true);
  };

  const handleBlur = (event) => {
    if (!event.target.value) {
      setIsFloating(false);
    }
  };

  return (
    <>
      <InputContainer>
        <Input
          style={{
            backgroundColor: `${
              validation && !isValidEmail && email !== ""
                ? "#ffcccb"
                : "transparent"
            }`,
          }}
          type={type}
          value={value}
          name={inputName}
          onFocus={handleFocus}
          onBlur={handleBlur}
          required={required}
          onChange={handleChange}
        />
        <Label isFloating={isFloating}>{label}</Label>
      </InputContainer>
    </>
  );
};

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 70px;
`;

const Input = styled.input`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  height: 100%;
  width: 100%;
`;

const Label = styled.label`
  position: absolute;
  translate: ${({ isFloating }) => (isFloating ? "0% -100%" : "0 0")};
  left: 40px;
  pointer-events: none;
  transition: 0.2s;
  font-size: ${({ isFloating }) => (isFloating ? "10px" : "15px")};
  color: ${({ isFloating }) => (isFloating ? "#333" : "#999")};
`;

export default FloatingLabelInput;
