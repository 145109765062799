import React from "react";
import { Scene } from "react-scrollmagic";
import { motion } from "framer-motion";

import { styled } from "styled-components";
import starSVG from "../../../assets/symbol.svg";
import video from "../../../assets/bg-transition.mp4";

export default function SectionVideo({ screen }) {
  function mapRangeScale(input, rMin, rMax) {
    var domainMin = 0;
    var domainMax = 0.7;
    var rangeMin = rMin;
    var rangeMax = rMax;

    // Normalize the input to a value between 0 and 1
    var normalizedInput = (input - domainMin) / (domainMax - domainMin);

    // Map the normalized input to the desired range
    var mappedValue = normalizedInput * (rangeMax - rangeMin) + rangeMin;
    if (input >= domainMax) {
      return rangeMax;
    }

    return mappedValue;
  }

  return (
    <Scene pin indicators={false} duration={1500} triggerHook={0}>
      {(progress) => (
        <Wrapper>
          <motion.div className="video-div">
            <Video
              dangerouslySetInnerHTML={{
                __html: `
            <video
            loop="true"
              muted
              autoplay
              playsinline="true"
           
              preload="auto"
            >
            <source src="${video}" type="video/mp4" />
            </video>
            
          `,
              }}
              // autoPlay={true}
              // loop={true}
              // controls={false}
              // playsInline
              // muted
              // src={video}
              // type="video/mp4"
            />
            {/* <video
              autoPlay={true}
              loop={true}
              controls={false}
              playsInline
              muted
              id="vid"
            >
              <source src={video} type="video/mp4" />
            </video> */}
          </motion.div>
          {screen.width < 800 ? (
            <div
              style={{
                backgroundSize: `${mapRangeScale(progress, 120, 480)}%`,
              }}
              className="star-div"
            ></div>
          ) : (
            <div
              style={{
                backgroundSize: `${mapRangeScale(progress, 30, 230)}%`,
              }}
              className="star-div"
            ></div>
          )}
        </Wrapper>
      )}
    </Scene>
  );
}

const Video = styled.div`
  video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f7;
  overflow: hidden;
  position: relative;
  .video-div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 100vh;

    // video {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    // }
  }
  .star-div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    position: relative;

    background: url(${starSVG});
    background-color: #f5f5f7;
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30%;
    @media (max-width: 800px) {
      background-size: 120%;
    }
    mix-blend-mode: lighten;
    left: 0;
  }
`;
