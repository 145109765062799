import { motion, useAnimation, useInView } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { styled } from "styled-components";

export default function SectionDescription({
  text,
  height,
  fontSize,
  fontWeight,

  screen,
}) {
  let ref = useRef();
  let isInView = useInView(ref, { amount: 0.5 });

  let control = useAnimation();

  useEffect(() => {
    if (isInView) {
      control.start("visible");
    }
  }, [isInView, control]);

  return (
    <Wrapper
      ref={ref}
      style={{
        height: height,
      }}
      fontWeight={fontWeight}
      fontSize={fontSize}
    >
      <motion.p
        style={{
          fontWeight: fontWeight,
          height: height,
        }}
        variants={{
          hidden: {
            opacity: 0,
            scale: 0.95,
            filter: "blur(5px)",
          },
          visible: {
            opacity: 1,
            scale: 1,
            filter: "blur(0px)",
          },
        }}
        initial="hidden"
        animate={control}
        transition={{ duration: 0.7, delay: 0.2 }}
      >
        {text}
      </motion.p>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 40vh;
  background-color: #f5f5f7;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 786px) {
    height: 80vh;
  }
  p {
    font-family: "GothamLight", -apple-system, BlinkMacSystemFont, avenir next,
      avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto,
      noto, arial, sans-serif;
    background-color: transparent;
    color: #1d1d1f;

    font-size: ${({ fontSize }) => fontSize};
    padding: 100px;

    text-align: center;
    @media (max-width: 786px) {
      padding: 10px;
      font-size: 24px;
    }
  }
`;
