import React, { useEffect, useRef } from "react";
import { styled } from "styled-components";
import logoSvg from "../../assets/logo-ship.svg";

import { motion, useAnimation, useInView } from "framer-motion";

export default function Logo({ screen, delay, startAnimation }) {
  let ref = useRef();

  let control = useAnimation();
  let isInView = useInView(ref, { amount: 1 });

  let ref1 = useRef();

  let control1 = useAnimation();
  let isInView1 = useInView(ref1, { amount: 1 });

  useEffect(() => {
    if (isInView) {
      control.start("visible");
      control1.start("visible");
    }
  }, [isInView, isInView1, control, control1]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "auto",
        width: "auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Wrapper screen={screen} ref={ref}>
        <motion.div
          variants={{
            hidden: {
              translateX: "-150%",
            },
            visible: {
              translateX: "0%",
            },
          }}
          initial="hidden"
          animate={startAnimation ? "visible" : control}
          transition={{
            duration: 1,
            delay: 0.1 + delay,
            type: "spring",
            damping: 7,
          }}
          className="ship-div"
        >
          <motion.img src={logoSvg} alt="Ship" />
        </motion.div>
        <motion.div
          variants={{
            hidden: {
              translateX: "0px",
              scale: 1,
              opacity: 1,
            },
            visible: {
              translateX: "-20px",
              scale: 0,
              opacity: 0,
            },
          }}
          initial="hidden"
          animate={startAnimation ? "visible" : control}
          transition={{
            duration: 0.2,
            delay: 0.1125 + delay,
          }}
          id="name"
          className="d"
        >
          D
        </motion.div>
        <motion.div
          variants={{
            hidden: {
              translateX: "0px",
              scale: 1,
              opacity: 1,
            },
            visible: {
              translateX: "-20px",
              scale: 0,
              opacity: 0,
            },
          }}
          initial="hidden"
          animate={startAnimation ? "visible" : control}
          transition={{
            duration: 0.2,
            delay: 0.175 + delay,
          }}
          id="name"
          className="e"
        >
          E
        </motion.div>
        <motion.div
          variants={{
            hidden: {
              translateX: "0px",
              scale: 1,
              opacity: 1,
            },
            visible: {
              translateX: "-20px",
              scale: 0,
              opacity: 0,
            },
          }}
          initial="hidden"
          animate={startAnimation ? "visible" : control}
          transition={{
            duration: 0.2,
            delay: 0.2625 + delay,
          }}
          id="name"
          className="l"
        >
          L
        </motion.div>
        <motion.div
          variants={{
            hidden: {
              translateX: "0px",
              scale: 1,
              opacity: 1,
            },
            visible: {
              translateX: "-20px",
              scale: 0,
              opacity: 0,
            },
          }}
          initial="hidden"
          animate={startAnimation ? "visible" : control}
          transition={{
            duration: 0.2,
            delay: 0.35 + delay,
          }}
          id="name"
          className="c"
        >
          C
        </motion.div>
        <motion.div
          variants={{
            hidden: {
              translateX: "0px",
              scale: 1,
              opacity: 1,
            },
            visible: {
              translateX: "-20px",
              scale: 0,
              opacity: 0,
            },
          }}
          initial="hidden"
          animate={startAnimation ? "visible" : control}
          transition={{
            duration: 0.2,
            delay: 0.4375 + delay,
          }}
          id="name"
          className="a"
        >
          A
        </motion.div>
        <motion.div
          variants={{
            hidden: {
              translateX: "0px",
              scale: 1,
              opacity: 1,
            },
            visible: {
              translateX: "-20px",
              scale: 0,
              opacity: 0,
            },
          }}
          initial="hidden"
          animate={startAnimation ? "visible" : control}
          transition={{
            duration: 0.2,
            delay: 0.525 + delay,
          }}
          id="name"
          className="r"
        >
          R
        </motion.div>
        <motion.div
          variants={{
            hidden: {
              translateX: "0px",
              scale: 1,
              opacity: 1,
            },
            visible: {
              translateX: "-20px",
              scale: 0,
              opacity: 0,
            },
          }}
          initial="hidden"
          animate={startAnimation ? "visible" : control}
          transition={{
            duration: 0.2,
            delay: 0.6125 + delay,
          }}
          id="name"
          className="g"
        >
          G
        </motion.div>
        <motion.div
          variants={{
            hidden: {
              translateX: "0px",
              scale: 1,
              opacity: 1,
            },
            visible: {
              translateX: "-20px",
              scale: 0,
              opacity: 0,
            },
          }}
          initial="hidden"
          animate={startAnimation ? "visible" : control}
          transition={{
            duration: 0.2,
            delay: 0.7 + delay,
          }}
          id="name"
          className="o"
        >
          O
        </motion.div>
      </Wrapper>
      <Slogan
        ref={ref1}
        variants={{
          hidden: {
            translateY: "100px",

            opacity: 0,
          },
          visible: {
            translateY: "0px",

            opacity: 1,
          },
        }}
        initial="hidden"
        animate={startAnimation ? "visible" : control1}
        transition={{
          duration: 0.5,
          delay: 0.3 + delay,
        }}
        className="slogan"
      >
        {" "}
        The Future to Freight is Now!
      </Slogan>
    </div>
  );
}
const Slogan = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;

  height: auto;
  font-family: "Martel", sans;
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  margin-bottom: 70px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  // width: 100%;
  width: auto;
  position: relative;
  height: 25vh;

  overflow: hidden;
  * {
    font-family: "Martel", sans;
    font-weight: 600;

    letter-spacing: 5px;
    letter-spacing: 5px;
  }
  #name {
    // display: ${({ progress }) => (progress === 1 ? "flex" : "flex")};
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    padding-top: 20px;
    font-size: 25px;
  }

  .ship-div {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100%;
    height: 100%;
    position: absolute;
    img {
      width: 180px;
      height: auto;
      @media (max-width: 800px) {
        width: 100px;
      }
    }
  }
`;
