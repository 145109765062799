import React from "react";
import { styled } from "styled-components";
import logoSvg from "../../assets/logo-ship.svg";

import { motion } from "framer-motion";

export default function NavBarLogo({ screen, progress }) {
  function mapRange(input, rMin, rMax) {
    var domainMin = 0;
    var domainMax = 1;
    var rangeMin = rMin;
    var rangeMax = rMax;

    // Normalize the input to a value between 0 and 1
    var normalizedInput = (input - domainMin) / (domainMax - domainMin);

    // Map the normalized input to the desired range
    var mappedValue = normalizedInput * (rangeMax - rangeMin) + rangeMin;
    // if (mappedValue >= rangeMax) {
    //   return rangeMax;
    // }
    return mappedValue;
  }

  function mapfortext(input, rMin, rMax, add) {
    var domainMin = 0;
    var domainMax = 1;
    var rangeMin = rMin;
    var rangeMax = rMax;

    // Normalize the input to a value between 0 and 1
    var normalizedInput = (input - domainMin) / (domainMax - domainMin);

    // Map the normalized input to the desired range
    var mappedValue = normalizedInput * (rangeMax - rangeMin) + rangeMin;
    // if (mappedValue >= rangeMax) {
    //   return rangeMax;
    // }
    if (input >= 0 + add) {
      return mappedValue;
    }
    return 1;
  }

  return (
    <>
      <Wrapper screen={screen} progress={progress}>
        <motion.div
          animate={{
            translateX: `${mapRange(progress, -150, 0)}%`,

            transition: {
              type: "spring",
              damping: 10,
            },
          }}
          className="ship-div"
        >
          <motion.img src={logoSvg} alt="Ship" />
        </motion.div>
        <motion.div
          style={{
            scale: `${mapfortext(progress, 1, 0, 0)}`,
            opacity: `${mapfortext(progress, 1, 0, 0)}`,
            translateX: `${mapRange(progress, 0, -20)}px`,
          }}
          id="name"
          className="d"
        >
          D
        </motion.div>
        <motion.div
          style={{
            opacity: `${mapfortext(progress, 1, 0, 0)}`,
            scale: `${mapfortext(progress, 1, 0, 0.125)}`,
            translateX: `${mapRange(progress, 0, -20)}px`,
          }}
          id="name"
          className="e"
        >
          E
        </motion.div>
        <motion.div
          style={{
            opacity: `${mapfortext(progress, 1, 0, 0)}`,
            scale: `${mapfortext(progress, 1, 0, 0.5)}`,
            translateX: `${mapRange(progress, 0, -20)}px`,
          }}
          id="name"
          className="l"
        >
          L
        </motion.div>
        <motion.div
          style={{
            opacity: `${mapfortext(progress, 1, 0, 0)}`,
            scale: `${mapfortext(progress, 1, 0, 0.375)}`,
            translateX: `${mapRange(progress, 0, -20)}px`,
          }}
          id="name"
          className="c"
        >
          C
        </motion.div>
        <motion.div
          style={{
            opacity: `${mapfortext(progress, 1, 0, 0)}`,
            scale: `${mapfortext(progress, 1, 0, 0.75)}`,
            translateX: `${mapRange(progress, 0, -20)}px`,
          }}
          id="name"
          className="a"
        >
          A
        </motion.div>
        <motion.div
          style={{
            opacity: `${mapfortext(progress, 1, 0, 0)}`,
            scale: `${mapfortext(progress, 1, 0, 0.875)}`,
            translateX: `${mapRange(progress, 0, -20)}px`,
          }}
          id="name"
          className="r"
        >
          R
        </motion.div>
        <motion.div
          style={{
            opacity: `${mapfortext(progress, 1, 0, 0)}`,
            scale: `${mapfortext(progress, 1, 0, 0.9)}`,
            translateX: `${mapRange(progress, 0, -20)}px`,
          }}
          id="name"
          className="g"
        >
          G
        </motion.div>
        <motion.div
          style={{
            opacity: `${mapfortext(progress, 1, 0, 0)}`,
            scale: `${mapfortext(progress, 1, 0, 0.975)}`,
            translateX: `${mapRange(progress, 0, -20)}px`,
          }}
          id="name"
          className="o"
        >
          O
        </motion.div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  // width: 100%;
  width: auto;
  position: relative;
  height: 100%;
  // @media (max-width: 800px) {
  //   width: 100%;
  // }
  overflow: hidden;
  * {
    font-family: "Martel", sans;
    font-weight: 600;

    letter-spacing: 5px;
  }
  #name {
    display: ${({ progress }) => (progress === 1 ? "flex" : "flex")};
    justify-content: center;
    align-items: center;
    // width: 20px;
    height: 100%;

    font-size: 21px;
    @media (max-width: 800px) {
      font-size: 15px;
    }
  }
  .ship-div {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100%;
    height: 100%;
    position: absolute;
    img {
      width: 100px;
      height: auto;
      @media (max-width: 800px) {
        width: 70px;
      }
    }
  }
`;
