import React, { useState } from "react";
import { styled } from "styled-components";
import bgLogin from "../../assets/bg-login.jpeg";
import bgLoginMobile from "../../assets/bg-login-mobile.jpg";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import FloatingLabelInput from "../../components/Inputs/FloatingLabelInput";
import axios from "axios";

let initialValues = {
  email: "",
  password: "",
};

export default function LoginPage({ screen, startingAnimationComplete }) {
  ////
  const [formData, setFormData] = useState(initialValues);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.email !== "" && formData.password !== "") {
      setLoading(true);
      const url = "https://test.delcargo.us/api/spa/login";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      try {
        const response = await axios.post(url, formData, { headers });
        // Handle the response here
        console.log(response.data);
      } catch (error) {
        // Handle any errors here
        console.error(error);
      }
      setLoading(false);
    }
  };

  function validateEmail(email) {
    // Regular expression pattern for email validation
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the pattern
    return pattern.test(email);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "email") {
      // Perform email validation
      const isValidEmail = validateEmail(value);

      // Pass the email and validation result to the parent component
      setIsValidEmail(formData.email === "" || isValidEmail);
    }

    if (formData.email !== "" && formData.password !== "") {
      setBtnDisable(false);
    }
  };

  ////

  ////
  let navigate = useNavigate();

  let variantDesktop = {
    hidden: {
      translateX: "100%",
    },
    visible: {
      translateX: "0%",
    },
  };
  let variantMobile = {
    hidden: {
      translateY: "100%",
    },
    visible: {
      translateY: "0%",
    },
  };

  let aimationVariant = screen.width >= 800 ? variantDesktop : variantMobile;
  return (
    <Wrapper
      btnDisabled={btnDisable}
      variants={{
        hidden: {
          scale: 1.1,
          filter: `blur(5px)`,
        },
        visible: {
          scale: 1,
          filter: `blur(0px)`,
        },
      }}
      initial="hidden"
      animate="visible"
      transition={{
        duration: 0.5,
        delay: `${startingAnimationComplete ? 0.1 : 3.25}`,
      }}
      screen={screen}
    >
      <motion.div
        variants={aimationVariant}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 0.7,
          delay: `${startingAnimationComplete ? 0.3 : 3.25}`,
        }}
        className="login-div"
      >
        <div className="heading-div">
          <h1>LogIn</h1> <div onClick={() => navigate("/")}>&lt; Back Home</div>
        </div>
        <div className="form-div">
          <form>
            <FloatingLabelInput
              type="email"
              label="Email"
              value={formData.email}
              inputName="email"
              required={true}
              isValidEmail={isValidEmail}
              email={formData.email}
              validation={true}
              handleChange={handleChange}
            />
            <FloatingLabelInput
              type={"password"}
              inputName={"password"}
              required={true}
              value={formData.password}
              label={"Password"}
              handleChange={handleChange}
            />
          </form>
          <span>Forget Password?</span>

          <span>
            Don’t have a Delcargo account?&nbsp;{" "}
            <p onClick={() => navigate("/register")}> &nbsp; Register</p>
          </span>
        </div>
        <button
          disabled={btnDisable}
          className="button-div"
          onClick={handleSubmit}
        >
          <div className="text-icon">
            <span className="text">LogIn</span>
            <span className="icon">
              <span className="inner-icon">
                {loading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="18" cy="12" r="0" fill="currentColor">
                      <animate
                        attributeName="r"
                        begin=".67"
                        calcMode="spline"
                        dur="1.5s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                      />
                    </circle>
                    <circle cx="12" cy="12" r="0" fill="currentColor">
                      <animate
                        attributeName="r"
                        begin=".33"
                        calcMode="spline"
                        dur="1.5s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                      />
                    </circle>
                    <circle cx="6" cy="12" r="0" fill="currentColor">
                      <animate
                        attributeName="r"
                        begin="0"
                        calcMode="spline"
                        dur="1.5s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                      />
                    </circle>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                    />
                  </svg>
                )}
              </span>
            </span>
          </div>
        </button>
      </motion.div>
    </Wrapper>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  justify-content: end;
  @media (max-width: 800px) {
    justify-content: center;
    align-items: start;
  }
  overflow-x: hidden;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: ${({ screen }) =>
    screen.width < 800 ? `url(${bgLoginMobile})` : `url(${bgLogin})`};
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  .login-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 35%;
    height: 85%;
    background-color: #f5f5f7;
    margin-right: 20px;
    @media (max-width: 800px) {
      margin-right: 0px;
      margin-top: 25px;
      width: 95%;
      height: 80%;
    }
    border-radius: 17px;

    .heading-div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 95%;
      height: 160px;

      border-bottom: 1px solid black;

      h1 {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 15px;
        width: 100%;
        height: 100%;
        color: #1f1f1d;
        font-size: 30px;
        font-weight: 400;
      }
      div {
        display: flex;
        justify-content: end;
        align-items: center;
        padding-right: 10px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        color: #fa5c40;
      }
    }
    .form-div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      gap: 10px;
      span {
        display: flex;
        flex-direction: row;

        p {
          cursor: pointer;
          color: #fa5c40;
        }
      }
      form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 65%;
        gap: 40px;

        input {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          width: 90%;
          height: 60px;
          border-radius: 15px;
          border: 1px solid rgba(119, 119, 119, 0.6);
          outline: none;
          color: black;
          font-weight: 600;

          &:focus {
            border: 1px solid black;
            font-weight: 400;
          }
        }
      }
    }
    .button-div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 150px;
      border-radius: 15px;
      background-color: ${({ btnDisabled }) =>
        btnDisabled ? "lightgray" : "black"};
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: ${({ btnDisabled }) =>
          btnDisabled ? "lightgray" : "#fa5c40"};
      }
      .text-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 100%;

        .text {
          display: flex;
          justify-content: start;
          align-items: center;
          width: 100%;
          height: 100%;
          font-weight: 400;
          font-size: 30px;
          padding-left: 15%;
          color: #f5f5f7;
        }
        .icon {
          display: flex;
          justify-content: end;
          align-items: center;
          width: 100%;
          height: 100%;

          font-size: 30px;
          padding-right: 15%;
          color: black;
          .inner-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 45px;
            border-radius: 100%;
            background-color: #f5f5f7;
          }
        }
      }
    }
  }
`;
