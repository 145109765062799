import React from "react";
import { styled } from "styled-components";
import bgAbout from "../../../assets/about.jpg";
import AboutButton from "../../../components/Button/aboutusBtn";
import { useNavigate } from "react-router-dom";
export default function SectionAbout({ screen }) {
  let navigate = useNavigate();
  return (
    <Wrapper>
      <div className="div-wrapper">
        <div className="div-1">
          <div className="div-1-1">
            <h1>DelCargo</h1>
          </div>
          <div className="div-1-2">
            <p>
              <br /> Delivering the Future: Redefining Logistics with <br />{" "}
              Innovation and Precision.
            </p>
          </div>
          <div className="div-1-3">
            <p>
              <br /> At DelCargo, we're your trusted partner in logistics,
              whether you're expanding globally or focusing on local markets.
              We're dedicated to building innovative solutions that empower your
              business to thrive, no matter the destination.
              <br />
              <br />
            </p>
          </div>
          <div className="div-1-4">
            <span onClick={() => navigate("/company")}>
              <AboutButton />
            </span>
          </div>
        </div>
        <div className="div-2">
          <div className="div-2-image"></div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 95vh;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
    height: auto;
    min-height: 100vh;
  }
  border-radius: 15px;

  * {
    border-radius: 15px;
  }
  .div-wrapper {
    display: flex;
    flex-direction: row;
    background-color: white;
    @media (max-width: 800px) {
      flex-direction: column-reverse;
    }
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 25px;
    @media (max-width: 800px) {
      padding: 10px;
    }

    ////
    .div-1 {
      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 15px;
      @media (max-width: 800px) {
        padding: 10px;
      }
      .div-1-1 {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        height: 100%;
        h1 {
          font-weight: 400;
          font-size: 20px;
        }
      }
      .div-1-2 {
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
        width: 100%;
        height: 100%;
        p {
          font-weight: 500;
          font-size: 30px;
          @media (max-width: 800px) {
            font-size: 20px;
          }
        }
      }
      .div-1-3 {
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
        width: 100%;
        height: 100%;
        p {
          font-weight: 400;
          font-size: 15px;
        }
      }

      .div-1-4 {
        display: flex;
        justify-content: left;
        align-items: center;

        width: 100%;
        height: 100%;
        @media (max-width: 800px) {
          padding: 20px;
        }
      }
    }
    ///
    ////
    .div-2 {
      display: flex;

      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .div-2-image {
        display: flex;

        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        @media (max-width: 800px) {
          height: 220px;
        }
        padding: 15px;
        background-image: url(${bgAbout});
        background-size: cover;
        // background-attachment: fixed;
        background-position: center;

        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    ////
  }
`;
