import { motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";
import SEO from "../../components/HeadSEOComponent/HeadSEO";

export default function PrivacyPolicy({ screen }) {
  return (
    <>
      <SEO title="Privacy Policy | DelCargo" description="" />
      <Wrapper>
        <div className="heading-div">
          <h1>Privacy Policy</h1>
          <h2>DELCARGO</h2>
        </div>
        <div className="content-div">
          <h2>Information Collection and Use</h2>
          <p>
            DelCargo is the sole owner of the information collected on this
            site. We know that your privacy is very important to you. That's why
            protecting any personal information, such as your name, address,
            e-mail address, or phone number that you provide to us is of the
            utmost importance to DelCargo.
          </p>
          <p>
            We will not sell, share, or rent this information to any outside
            parties, except as outlined in this policy. We collect information
            from our customers to process orders/purchase requests and better
            serve you with pertinent information, such as order confirmations
            and order status updates. Information collected includes your name,
            shipping address, billing address, telephone numbers, e-mail
            address, and payment information such as your credit card number.
          </p>
          <p>
            <b>Note:</b> Although we collect payment information, we do not
            store this information on any of our servers.
          </p>
          <h3>Registration</h3>
          <p>
            You can browse DelCargo.com without revealing any personal
            information about yourself. We collect some information
            automatically from your computer while you browse our sites, such as
            where you go on DelCargo.com and what you do there.
          </p>
          <p>
            In order to enable access to our services and process your
            orders/purchase requests placed on this website, you must first
            complete the registration form.
          </p>
          <p>
            During registration, you will be required to provide your contact
            information, which includes your name, e-mail address, telephone
            number, and street address. This information is used to provide you
            with important DelCargo services such as automated order status
            updates via e-mail.
          </p>
          <h3>Order</h3>
          <p>
            Most of the information collected in the registration process will
            be used to process orders. During the order process, you will have
            to provide financial information such as your credit card number,
            expiration date, wire transfer, or check information. This
            information is used for billing purposes and to fulfill your order
            and is not stored statically on our servers.
          </p>
          <p>
            If we have trouble processing an order, we will use this contact
            information to get in touch with you. To properly process your
            credit card information, we must share your personal and financial
            information with the merchant bank for authorization and approval.
            This process is protected by an enhanced security system. We do not
            share your personal and financial information with any third
            parties, except those specified in the Sharing section of this
            policy.
          </p>
          <h3>Cookies</h3>
          <p>
            We customize certain Web page content based upon your browser type
            and other information provided by our cookie.
          </p>
          <p>
            If you choose to reject the cookies, you can still browse our store
            but will be unable to use the shopping cart to buy merchandise. The
            DelCargo shopping cart cannot function without cookies enabled so
            that the necessary information to process your order is retained. If
            you disable cookies, DelCargo will be unable to accept your online
            order.
          </p>
          <p>
            <b>Note:</b> We will not share any personally identifiable
            information provided by this cookie with any third party. We will,
            however, link data stored in cookies to the personally identifiable
            information you submitted while on our site. This allows us to
            personalize your shopping experience and discern user preferences to
            evoke subconscious feelings of familiarity and assurance.
          </p>
          <h3>Log Files</h3>
          <p>
            We use IP addresses to analyze trends, administer the site, track
            user movement, and gather broad demographic information for
            aggregate use. We do not link IP addresses to personally
            identifiable information, and we do not distribute or share IP
            information with any third parties.
          </p>
          <h3>Sharing</h3>
          <p>
            We may employ third-party companies to perform functions on our
            behalf. These functions may include order fulfillment, package
            delivery, marketing assistance, postal and e-mail delivery, customer
            service, data analysis, and credit processing. The third parties we
            contract for these purposes have limited access to your personal
            information and may not use it for other purposes.
          </p>
          <p>
            As our business continues to evolve, we may acquire or sell business
            units or subsidiaries. Customer information is one of the business
            assets that would be transferred in these types of transactions. In
            such a situation, your personally identifiable information would
            only be used in a manner consistent with our pre-existing Privacy
            Notice.
          </p>
          <p>
            We reserve the right to disclose your personally identifiable
            information as required by law, and when we believe that disclosure
            is necessary to protect our rights and/or comply with a judicial
            proceeding, court order, or legal process served on our website.
          </p>
          <h3>Testimonials</h3>
          <p>
            The testimonials section of the DelCargo website is for the purpose
            of sharing positive experiences. The testimonials section is
            moderated to ensure accurate and positive testimonials. Published
            testimonials are genuine and are not edited or altered by DelCargo.
            DelCargo reserves the right to post or remove any testimonial at its
            discretion.
          </p>
          <p>
            By posting your comment(s) or testimonial(s) ("Testimonial") on our
            website, you give us permission to reprint or use the Testimonial in
            connection with our business.
          </p>
          <p>You also are agreeing to the following:</p>
          <ul>
            <li>
              The reprint or use of the Testimonial will be at our discretion
              and without compensation.
            </li>
            <li>
              We may use the Testimonial with or without any pseudo (pen) name
              credit.
            </li>
            <li>
              Our right to use the Testimonial is perpetual and may be assigned
              by us.
            </li>
            <li>
              We may use the Testimonial locally, nationally, or globally, and
              such use may be made in any media, information, or communication
              platform (including internet advertising) currently in use or
              later developed.
            </li>
            <li>
              You and not someone else composed and posted the Testimonial.
            </li>
          </ul>
          <p>
            Other than your pseudo (pen) name, which we may or may not use, your
            personal contact information will not be disclosed in connection
            with our reprint or use of your Testimonial.
          </p>
          <h3>Children</h3>
          <p>
            DelCargo does not sell products for purchase by children.
            Youth-oriented products are sold for purchase by adults only. If you
            are under the age of 18, you may only use DelCargo in the presence
            of a consenting parent or guardian. We will not knowingly or
            intentionally collect personal information via the internet from
            children under the age of 13. DelCargo is committed to protecting
            the welfare and privacy of children.
          </p>
          <h3>Links</h3>
          <p>
            This website contains links to other sites. Please be aware that
            DelCargo is not responsible for the privacy practices of such other
            sites. We encourage our users to be aware when they leave our site
            and to read the privacy statements of each and every website that
            collects personally identifiable information. This privacy statement
            applies only to information collected by this website.
          </p>
          <h3>Security</h3>
          <p>
            DelCargo takes precautions to protect its customers' information.
            When you submit sensitive information via the website, your
            information is protected both online and offline. We will never ask
            you for your password in an unsolicited phone call or in an
            unsolicited e-mail. Also, if you are using a computer that others
            have access to, such as one in a computer lab, internet cafe, or
            public library, always remember to log out and close your browser
            window when leaving our site.
          </p>
          <p>
            When our registration/order form asks you to enter sensitive
            information (such as your credit card number), that information is
            encrypted and is protected using SSL encryption software. To learn
            more about SSL, follow this link: www.verisign.com.
          </p>
          <p>
            While on a secure page, such as our order form, the lock icon on the
            bottom of web browsers such as Netscape Navigator and Microsoft
            Internet Explorer becomes locked, as opposed to unlocked or open
            when you are just browsing.
          </p>
          <p>
            If you have any questions about the security at our website, please
            send an e-mail to our Customer Service Department at
            privacy@delcargo.com.
          </p>
          <p>
            <b>Note:</b> DelCargo will never share your credit card information
            via e-mail.
          </p>
          <p>
            <b>Notification of Changes</b>
          </p>
          <p>
            If we decide to change our privacy policy, we will post those
            changes to this privacy statement, the homepage, and other places we
            deem appropriate so that you are aware of what information we
            collect, how we use it, and under what circumstances, if any, we
            disclose it. We reserve the right to modify this privacy statement
            at any time, so please review it frequently. If we make material
            changes to this policy, we will notify you here, by e-mail, or by
            means of a notice on our homepage.
          </p>
          <p>
            <b>Contact Information</b>
          </p>
          <p>
            If you have any questions or suggestions regarding our privacy
            policy, please contact us at:
          </p>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 10px;
  }

  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    list-style: square;
    padding-left: 100px;
    @media (max-width: 800px) {
      padding-left: 15px;
    }
    li {
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
    }
  }

  .content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 70vw;

    @media (max-width: 800px) {
      width: 100vw;
      padding: 20px;
      * {
        text-align: left;
      }
    }
    height: auto;

    h2 {
      font-size: 40px;
      font-weight: 500;
      padding-bottom: 20px;
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (max-width: 800px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .heading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 80vh;

    padding: 50px;
    @media (max-width: 800px) {
      padding: 10px;
      height: 350px;
    }
    h2 {
      font-size: 30px;
      font-weight: 400;
      color: #1d1d1f;
    }
    h1 {
      font-size: 60px;
      font-weight: 600;
      color: #1d1d1f;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 50px;
        font-weight: 500;
      }
    }
  }
`;
