import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

export default function NoMatch(params) {
  return (
    <Wrapper>
      <h1>404 - Not Found</h1>
      <Link to="/">
        {" "}
        <span>&lt; Go to Home</span>
      </Link>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  gap: 20px;
  h1 {
    font-weight: 600;
    font-size: 40px;
    color: #1d1d1f;
  }
`;
